import React, { useEffect, useState } from 'react';
import '../Settings.scss';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Form, InputGroup } from 'react-bootstrap';
import { entityActions } from '../../../../../../../../../_actions';
import { settingSelector } from '../../../../../../../../../_reducers/setting.reducer';

import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '../UploadIcon';
import { ErrorMessage } from 'formik';
import { choiceActions } from '../../../../../../../../../_actions/choice.action';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Loader from '../Loader';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { CountryDropdown } from 'react-country-region-selector';

const PartnershipStepOne = ({
  formik,
  certificateFile,
  certificateLabel,
  mandateFile,
  mandateLabel,
  handleRemoveImage,
  licenseFile,
  licenseLabel,
  partnershipFile,
  partnershipLabel,
  businessKraPinCerficate,
  handleImage,
  kraLabel,
  kraFile,
  loader,
}: any) => {
  const [render, setRender] = useState(true);
  const industries = useSelector(
    (state: any) => state.getIndustriesReducer.data
  );
console.log(kraLabel,kraFile,"kraFilekraFile");

  const operationModes = useSelector(
    (state: any) => state.getBusinessOperationModesReducer.data
  );

  const [renderSubmit, setRenderSubmit] = useState(true);
  const dispatch = useDispatch();
  const dataEntity: any = useSelector((state) => settingSelector(state));

  React.useEffect(() => {
    if (
      renderSubmit &&
      dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      console.log(
        'Succesfully Sumbit Entity list',
        dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS
      );
      setRenderSubmit(false);
    }
  }, [dataEntity, renderSubmit]);

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      // dispatch(entityActions.getEntityActivities() as any)
    }
  }, [render]);

  useEffect(() => {
    console.log('dispatching action');
    dispatch(choiceActions.getIndustries() as any);
    dispatch(choiceActions.getOperatingModes() as any);
  }, [dispatch]);

  const [fileSize, setFileSize] = useState<any>('');
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  // const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
    }
  }, [render]);

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  console.log('kraFile >>>> Step One', kraFile);
  console.log('businessCertificate file >>>> Step One', certificateFile);

  const [kraAccordion, setKraAccordion] = useState<boolean>(false);
  const [dataaccodiyan, setDataccodiyan] = useState<boolean>(false);
  const taxAccordionHasErrors = () => {
    const fieldsToCheck = ['businessKraPinCertificate', 'kraPin'];
    return fieldsToCheck.some((field) => formik.errors[field]);
  };
  console.log(formik, "formikformik");

  // const imageUrl =
  //   'https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/documents/frontID-6ab96d5d-8af3-4e1f-8895-7069fb56f1f32023-08-22.png';
  return (
    <>
      <p className="complete-profile__businesstypo">Partnership details</p>
      <div className="calss_accordion_contenar">
        <Form>
          <div className="input-form-wrapper dot">
            <label>Business name</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Control
                name="businessName"
                value={formik.values.businessName}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.businessName && formik.errors.businessName
                }
                type="text"
              />
            </InputGroup>
            <p className="error-message">
              {' '}
              <ErrorMessage name="businessName" component="div" />
            </p>
          </div>
          <div className="input-form-wrapper dot">
            <label>Business address</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Control
                name="businessAddress"
                value={formik.values.businessAddress}
                onChange={formik.handleChange}
                type="text"
                isInvalid={
                  formik.touched.businessAddress &&
                  Boolean(formik.errors.businessAddress)
                }
              />
            </InputGroup>
            <p className="error-message">
              {' '}
              <ErrorMessage name="businessAddress" component="div" />
            </p>
          </div>
          <div className="input-form-wrapper dot">
            <label>Country</label>
            <CountryDropdown
              id="CountryDropdown_class"
              value={formik.values.country}
              onChange={(e, event) => {
                // setCountryError(!!e ? false : true);
                // setCountry(e);
                formik.handleChange(event);
              }}
              name="country"
              classes={
                'form-text form-control' +
                (formik.errors.country && formik.touched.country
                  ? ' is-invalid'
                  : '')
              }
            />
            <p className="error-message">
              {' '}
              <ErrorMessage name="country" component="div" />
            </p>
          </div>

          {/* <div className="input-form-wrapper dot">
            <label>Website</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Control
                name="website"
                value={formik.values.website}
                onChange={formik.handleChange}
                type="text"
                isInvalid={
                  formik.touched.website && Boolean(formik.errors.website)
                }
              />
            </InputGroup>
            <p className="error-message">
              {' '}
              <ErrorMessage name="website" component="div" />
            </p>
          </div> */}
          <div className="input-form-wrapper dot">
            <label>Business activity</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Select
                name="businessIndustry"
                value={formik.values.businessIndustry}
                onChange={(e) => {
                  formik.handleChange(e)
                  const findData = industries.find((data: any) => data.id == e.target.value)
                  formik.setFieldValue('business_industry_name', findData?.value);
                }} isInvalid={
                  formik.touched.businessIndustry &&
                  Boolean(formik.errors.businessIndustry)
                }
              >
                <option>Select industry</option>
                {Array.isArray(industries)
                  ? industries?.map((element: any) => {
                    return (
                      <option key={element.id} value={element.key}>
                        {element.value}
                      </option>
                    );
                  })
                  : 'data not found'}
              </Form.Select>
            </InputGroup>
            <p className="error-message">
              {' '}
              <ErrorMessage name="businessIndustry" component="div" />
            </p>
          </div>
          {/* <div className="input-form-wrapper dot">
            <label>Business operating mode</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Select
                name="operatingMode"
                value={formik.values.operatingMode}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.operatingMode &&
                  Boolean(formik.errors.operatingMode)
                }
              >
                <option>Business Operating Modes</option>
                {Array.isArray(operationModes)
                  ? operationModes?.map((element: any) => {
                      return (
                        <option key={element.id} value={element.key}>
                          {element.value}
                        </option>
                      );
                    })
                  : 'data not found'}
              </Form.Select>
            </InputGroup>
            <p className="error-message">
              {' '}
              <ErrorMessage name="operatingMode" component="div" />
            </p>
          </div> */}
          <div className="input-form-wrapper dot">
            <label>Business registration number</label>
            <InputGroup className="doc-input-wrapper">
              <Form.Control
                name="businessCerNum"
                value={formik.values.businessCerNum}
                onChange={formik.handleChange}
                type="text"
                isInvalid={
                  formik.touched.businessCerNum && formik.errors.businessCerNum
                }
              />
            </InputGroup>
            <p className="error-message">
              {' '}
              <ErrorMessage name="businessCerNum" component="div" />
            </p>
          </div>
          <div className="flax_data_list">
            <Tooltip title="Upload scanned copy or image file (File-size: Max 1 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
              <div style={{ width: '100%', display: 'block' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label className="upload-label">
                    Upload business registration certificate
                  </label>
                  <div className="required_marker"></div>
                </div>
                <label
                  style={{ padding: '5px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                  //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                        formik.values?.businessDocs?.data?.data
                          ?.businessCertificate?.file_name != null
                        ? formik.values?.businessDocs?.data?.data
                          ?.businessCertificate?.file_name
                        : certificateFile}
                    </span>
                    {/* {certificateFile === certificateLabel ? (
                    <UploadIcon />
                  ) : (
                    <CloseIcon />
                  )} */}
                  </div>
                  <input
                    type="file"
                    accept=".pdf, .jpg, .jpeg, .png"
                    name="businessCertificate"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.businessCertificate ? (
                      <ErrorMessage
                        name="businessCertificate"
                        component="div"
                      />
                    ) : null}
                  </p>
                </label>
              </div>
            </Tooltip>
            {certificateFile === certificateLabel ? (
              <div className="upload-icon">
                {' '}
                <UploadIcon />
              </div>
            ) : (
              <div
                className="upload-icon"
                onClick={() => {
                  // remove_data(
                  //   groupOneName[i].KRN_certi_new
                  // );
                  handleRemoveImage('businessCertificate', formik);
                  console.log('remove');
                }}
              >
                <CloseIcon />
              </div>
            )}
          </div>
          <div style={{ height: '15px' }}></div>
          <div className="flax_data_list">
            <Tooltip title="Upload scanned copy or image file (File-size: Max 1 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
              <div style={{ width: '100%', display: 'block' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label className="upload-label">
                    Upload partnership deed
                  </label>
                  <div className="required_marker"></div>
                </div>
                <label
                  style={{ padding: '5px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                  //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                        formik.values?.businessDocs?.data?.data?.partnershipDeed
                          ?.file_name != null
                        ? formik.values?.businessDocs?.data?.data
                          ?.partnershipDeed?.file_name
                        : partnershipFile}
                    </span>
                    {/* {partnershipFile === partnershipLabel ? (
                    <UploadIcon />
                  ) : (
                    <CloseIcon />
                  )} */}
                  </div>
                  <input
                    type="file"
                    accept=".pdf, .jpg, .jpeg, .png"
                    name="partnershipDeed"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.partnershipDeed ? (
                      <ErrorMessage name="partnershipDeed" component="div" />
                    ) : null}
                  </p>
                </label>
              </div>
            </Tooltip>
            {partnershipFile === partnershipLabel ? (
              <div className="upload-icon">
                {' '}
                <UploadIcon />
              </div>
            ) : (
              <div
                className="upload-icon"
                onClick={() => {
                  // remove_data(
                  //   groupOneName[i].KRN_certi_new
                  // );
                  handleRemoveImage('partnershipDeed', formik);
                }}
              >
                <CloseIcon />
              </div>
            )}
          </div>
          {/* <div className="flax_data_list">
            <Tooltip
              className="doc-input-wrapper"
              title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf)"
            >
              <div style={{ width: '100%', display: 'block' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label className="upload-label">
                    Upload license (if applicable)
                  </label>
                   <div className="required_marker"></div> 
                </div>
                <label
                  style={{ padding: '5px 0px 0px 0px' }}
                  className="complete-profile__upload mb-0"
                >
                  <div
                    // id='afafafsdaFfsf'
                    className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                  >
                    <span className="complete-profile__upload__txt">
                      {formik.values?.businessDocs?.data != null &&
                      formik.values?.businessDocs?.data?.data?.license
                        ?.file_name != null
                        ? formik.values?.businessDocs?.data?.data?.license
                            ?.file_name
                        : licenseFile}
                    </span>
                  </div>
                  <input
                    type="file"
                    accept=".pdf, .jpg, .jpeg, .png"
                    // disabled={submitted}
                    name="license"
                    onChange={(e) => {
                      handleImage(e, formik);
                    }}
                  />
                  {1024 * 1024 <= fileSize ? (
                    <div className="error-message">
                      File size too large, max file size is 1 Mb
                    </div>
                  ) : null}
                  <p className="error-message">
                    {' '}
                    {formik.errors.license ? (
                      <ErrorMessage name="license" component="div" />
                    ) : null}
                  </p>
                </label>
              </div>
            </Tooltip>
            {licenseFile === licenseLabel ? (
              <div className="upload-icon">
                {' '}
                <UploadIcon />
              </div>
            ) : (
              <div
                className="upload-icon"
                onClick={(e) => {
                  // remove_data(
                  //   groupOneName[i].KRN_certi_new
                  // );
                  handleRemoveImage('license', formik);
                  console.log('remove');
                }}
              >
                <CloseIcon />
              </div>
            )}
          </div> */}
        </Form>
      </div>
      <div className="calss_accordion_contenar">
        <div
          className="maian_adaat_listtts"
          onClick={() => {
            if (kraAccordion === false) {
              setKraAccordion(true);
              setDataccodiyan(false);
            } else {
              setKraAccordion(false);
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <div
              className={` ${!taxAccordionHasErrors() ? 'avayar_text' : 'avayar_text_error'
                }`}
            >
              {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
              <p className="accordion_p_data_contean">Tax details</p>
            </div>
            {taxAccordionHasErrors() && (
              <ReportProblemIcon style={{ color: 'rgb(255, 101, 101)' }} />
            )}
            {!taxAccordionHasErrors() ? (
              <>
                {kraAccordion === false ? (
                  <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                ) : (
                  <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                )}
              </>
            ) : (
              ''
            )}
          </ThemeProvider>
        </div>

        {kraAccordion === true ? (
          <>
            <div className="input-form-wrapper dot">
              <label>Partnership tax indentification number</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="kraPin"
                  value={formik.values.kraPin}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.kraPin && Boolean(formik.errors.kraPin)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="kraPin" component="div" />
              </p>
            </div>
            <div className="flax_data_list">
              <Tooltip title="Upload document showing tax identification number (File-size: Max 1 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                <div style={{ width: '100%', display: 'block' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className="upload-label">
                      Upload Tax certificate
                    </label>
                    <div className="required_marker"></div>
                  </div>
                  <label
                    style={{ padding: '5px 0px 0px 0px' }}
                    className="complete-profile__upload mb-0"
                  >
                    <div
                      // id='afafafsdaFfsf'
                      className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                    >
                      <span className="complete-profile__upload__txt">
                        {kraFile}
                      </span>
                    </div>
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png"
                      name="businessKraPinCertificate"
                      onChange={(e) => {
                        handleImage(e, formik);
                      }}
                    />
                    {1024 * 1024 <= fileSize ? (
                      <div className="error-message">
                        File size too large, max file size is 1 Mb
                      </div>
                    ) : null}
                    <p className="error-message">
                      {' '}
                      {formik.errors.businessKraPinCertificate ? (
                        <ErrorMessage
                          name="businessKraPinCertificate"
                          component="div"
                        />
                      ) : null}
                    </p>
                  </label>
                </div>
              </Tooltip>
              {kraFile === kraLabel ? (
                <div className="upload-icon">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="upload-icon"
                  onClick={() => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    handleRemoveImage('businessKraPinCertificate', formik);
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      {loader && <Loader />}
    </>
  );
};

export default PartnershipStepOne;
