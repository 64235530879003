import React, { useCallback, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PageHeader from "../PageHeader";
import "../Bill/Bill.scss";
import ApproveTable from "./components/Approve_payments/Approve_payments";
import { PaymentInitiated } from './components/PaymentInitiated/PaymentInitiated';
import Bulk_payment_summary from './components/Bulk_payment_summary/Bulk_payment_summary'
import Transaction_Summary from './components/Transaction_Summary/Transaction_Summary'
import { AuthoriseTransaction } from './components/AuthoriseTransaction/AuthoriseTransaction'
import { useDispatch, useSelector } from "react-redux";
import { entityActions } from "../../../../_actions/entity.actions";
import { isUserAuthenticated } from "../../../../_helpers";
import { payablesActions } from "../../../../_actions/payables.actions";
import { userActions } from "../../../../_actions";
import { selectAllPayablesListData } from "../../../../_reducers/payables.reducer";
export class HttpClient {
    static get() {
        return Promise.resolve(`buyPrice,buy_qty,sellPrice,sell_qty,enter,
          3.37,1,3.41,1,0
          3.33,1,3.37,1,0
          3.55,1,3.7,1,1
          3.23,1,3.27,1,0
          3.21,1,3.25,1,0`);
    }
}
interface Props {
    onSave?: any,
    onColes?: any
}
const BillsApproval_bulk = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [buttonType, setButtonType] = useState('BillsApproval');
    const [transactin_summary, setTransaction_summry] = useState<any>()
    const [transRender, setTransRender] = useState(false);
    const [requestRender, setrequestRender] = useState(true);
    const [loading, setLoading] = useState(false);
    const [responseRender, setresponseRender] = useState(false);
    const [otpFailed, setotpFailed] = useState(false);
    const [data_aapro_data, setData_aapro_data] = useState<any>()
    const [time, setTime] = useState(0);
    const [enableButton, setEnableButton] = useState(false);
    const [Displaydata, setDisplaydata] = React.useState(false)

    const handleSubmit_resend = (data: any) => {
        if (data == 1) {
            setEnableButton(false)
            setTime(120)
            dispatch(userActions.getOtp('') as any)
            dispatch(payablesActions.getOtpverifay() as any);
            setTimeout(() => {
                setEnableButton(true);
            }, 120000)
        } else if (data == 2) {
            setEnableButton(false)
            setTime(120)
            setTimeout(() => {
                setEnableButton(true);
            }, 120000)
        }


    };
    if (time > 0) {
        setTimeout(() => {
            setTime(time - 1)
        }, 1000)
    }
    const payablesListData = useSelector((state) =>
        selectAllPayablesListData(state)
    );
    var localUser: any = localStorage.getItem("user");
    useEffect(() => {
        if (
            transRender &&
            payablesListData?.OTP_SUCCESS == true
        ) {
            setTransRender(false);
            setButtonType("PaymentInitiated")
        }
    }, [payablesListData, transRender]);
    useEffect(() => {
        if (requestRender && payablesListData?.OTP_REQUEST == true) {
            setLoading(payablesListData.OTP_REQUEST);
            setresponseRender(true);
            setrequestRender(false);
            setTransRender(true);
            setotpFailed(true);
        }
    }, [payablesListData, requestRender]);

    useEffect(() => {
        if (responseRender && payablesListData?.OTP_REQUEST == false) {
            setLoading(false);
            setrequestRender(true);
            setresponseRender(false);
        }
    }, [payablesListData, responseRender]);
    useEffect(() => {
        if (
            otpFailed &&
            payablesListData?.OTP_FAILURE !== undefined
        ) {
            setTransRender(false);
            setotpFailed(false);
            setButtonType("AuthoriseTransaction")
        }
    }, [payablesListData, otpFailed]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [csvname, setCsvnamedata] = React.useState('bulkadd')
    const [render, setRender] = useState(true);
    const dispatch = useDispatch()

    const changeTime = () => {
        const tokentwo: any = localStorage.getItem('tokentwo')
        console.log(new Date().getTime(), new Date(JSON.parse(tokentwo)).getTime(), new Date().getTime() - new Date(JSON.parse(tokentwo)).getTime())
        if (new Date().getTime() - new Date(JSON.parse(tokentwo)).getTime() >= 1800000) {
            console.log("here 30 minutes upper")
            setRender(false)
            dispatch(entityActions.getBilldata() as any)
        } else {
            console.log("here 30 minutes lower")
        }
        setTimeout(() => changeTime(), 1830000)
    }

    useEffect(() => {
        var localdata = localStorage.getItem('user')
        changeTime()

    }, [render, isUserAuthenticated]);
    useEffect(() => {
        const popup = searchParams.get('popup');
        const BILLSBULK: any = searchParams.get('BILLSBULK')
        setCsvnamedata(BILLSBULK)
        if (popup == '1') {
            setIsOpen(true)
        }
    }, [searchParams]);
    return (
        <>
            {buttonType == 'BillsApproval' ?
                <>
                    {!!localUser && <div className="bill-container">
                        <div className="bill-container__payables">
                            <PageHeader
                                title="Approve Payments"
                            />

                            <div className={'height_two'}>
                                <ApproveTable
                                    submit_data={(el: any) => {
                                        setData_aapro_data(el)
                                        setButtonType("Transaction_Summary")
                                    }}
                                />
                            </div>
                        </div>
                    </div>}
                </> : buttonType == 'Transaction_Summary' ?
                    <Transaction_Summary
                        onSave={(fototer_detels: any) => {
                            setButtonType("AuthoriseTransaction")
                            setTransaction_summry(fototer_detels)
                            handleSubmit_resend(2)
                        }}
                        onCancel={() => { setButtonType("BillsApproval") }}
                    /> :
                    buttonType == 'AuthoriseTransaction' ?
                        <AuthoriseTransaction
                            time={time}
                            handleSubmit_resend={handleSubmit_resend}
                            enableButton={enableButton}
                            id={data_aapro_data}
                            loading={loading}
                            onSave={() => { }}
                            onCancel={() => { setButtonType("Transaction_Summary") }}
                        /> :
                        buttonType == 'PaymentInitiated' ?
                            <PaymentInitiated counterpart={transactin_summary}
                                id={data_aapro_data}
                                setDisplaydata={setDisplaydata}
                                type='Send money'
                                onSave={() => { setButtonType('Bulk_payment_summary') }}
                                onCancel={() => { setButtonType('AuthoriseTransaction') }} /> :
                            buttonType == 'Bulk_payment_summary' ?
                                <Bulk_payment_summary
                                    id={data_aapro_data}
                                    setDisplaydata={setDisplaydata}
                                    Displaydata={Displaydata}
                                />
                                :
                                ""
            }
        </>
    );
};
export default BillsApproval_bulk;