import { Box, Grid, IconButton, Typography } from '@mui/material';
import './CeretePaymentLinkFrom.scss';
import { Button } from 'react-bootstrap';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import React, { useEffect, useState, useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { getKESFormate } from '../../../../../../_helpers/common';
import MaskedInput from 'react-text-mask';
import { Col, Form, InputGroup, Nav, Row, Tab, Tabs } from 'react-bootstrap';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllBalanceLIstData } from '../../../../../../_reducers/balance.reducer';
import { balanceActions } from '../../../../../../_actions/balance.actions';
import Setp2 from './components/setp1/setp2';
import Preview from './components/setp1/Preview';
import { selectPaymentJourneyData } from '../../../../../../_reducers/paymentJourney.reducer';
import { paymentJourneyActions } from '../../../../../../_actions/paymentJourney.actions';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Detailspayment from './components/setp1/detailspayment';
import LinkPage from './components/setp2/LinkPage';
import { useSearchParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { getProperties } from '../../../../../../_helpers';
import posthog from 'posthog-js';
import amplitude from 'amplitude-js';

const CeretePaymentLinkFrom = () => {
  const dispatch = useDispatch();

  const [dataadd, setDataadd] = useState<any>({
    Business_name: '',
    Upload_logo: '',
    Description: '',
    Amount: 0,
    Currancy: '',
  });
  const navigate = useNavigate();
  const [invoicefile, setinvoicefile] = useState('Upload logo');
  const [fileSize, setFileSize] = useState<any>('');
  const [valid, setValid] = useState({
    Business_name: 'Please enter your business name.',
    Upload_logo: 'Please enter your upload logo.',
    Description: '',
    Amount: 'Please enter your amount.',
    Currancy: 'Please enter your currency.',
  });
  const [pages, setPages] = useState(1);
  const [bankDetailsRender_two, setBankDetailsRender_two] = useState(true);
  const [currencies, setCurrencies] = useState([]);
  const [submitButton, setSubmitButton] = useState(false);
  const numberMask = createNumberMask({
    prefix: '',
    suffix: '', // This will put the dollar sign at the end, with a space.
  });
  const [loading, setLoading] = useState(false);
  const [contryCode, setcountry_code] = useState('ke');
  const [render, setRender] = useState(true);
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  const [datapage, setDATAPAGE] = useState(true);
  const [apiredusar, setApiredusar] = useState(false);
  const [apiredusar_two, setApiredusar_two] = useState(false);

  const [linkDATA, setLinkdata] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [getredusar, setGetredusar] = useState(false);
  const [updetid, setUpdetid] = useState('');
  const [updetid_LIST, setUpdetid_LIST] = useState('');
  const [countryListApi, setCountrtListApi] = useState([]);
  const [startgetapi, setStartgetapi] = useState(true);
  const [country, setCountry] = useState<any>();
  const [country_List, setCountry_List] = useState<any>('1');
  const [countryNameget, setCountryNameget] = useState('');
  const data = useSelector((state) => selectAllBalanceLIstData(state));
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  const options = useMemo(() => countryList().getData(), []);

  const properties = getProperties();
  const eventProperties = {
    userId: !!properties && !!properties.user_id ? properties.user_id : null,
    userName: !!properties && !!properties.name ? properties.name : null,
    email: !!properties && !!properties.email ? properties.email : null,
    pageUrl: window.location.href,
  };

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(balanceActions.getTopUpDetails_doller() as any);
      dispatch(paymentJourneyActions.getCountries() as any);
    }
  }, [render]);
  useEffect(() => {
    if (!!options && !!countryNameget) {
      const findcurrencies: any = options.find(
        (data: any) => data.label == countryNameget
      );
      var country_sortname_list = findcurrencies?.value?.toLowerCase();
      setcountry_code(country_sortname_list);
    }
  }, [countryNameget, options]);
  useEffect(() => {
    if (startgetapi && dataList?.GETCOUNTRIES_SUCCESS !== undefined) {
      setCountrtListApi(dataList?.GETCOUNTRIES_SUCCESS);
      setStartgetapi(false);
    }
  }, [startgetapi, dataList]);
  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
    };
  };
  const handleImage = (e: any) => {
    setFileSize(e.target.files[0].size);
    setinvoicefile(e.target.files[0].name);

    getBase64(e.target.files[0], (result: any) => {
      setinvoiceImage(result);
      setDataadd({
        Business_name: dataadd.Business_name,
        Upload_logo: result,
        Description: dataadd.Description,
        Amount: dataadd.Amount,
        Currancy: dataadd.Currancy,
      });
    });
  };
  React.useEffect(() => {
    const popup = searchParams.get('short_code');
    if (!!popup) {
      dispatch(paymentJourneyActions.getPaymentLinkByShortcode(popup) as any);
      setGetredusar(true);
      setUpdetid(popup);
    }
  }, [searchParams]);
  const HandSubmit = () => {
    if (!!dataadd?.Business_name) {
      var body: any = {};

      if (dataadd.Amount == 0) {
        body = {
          amount: 0,
          business_name: dataadd.Business_name,
          description: dataadd.Description,
          file_name:invoicefile,
          callback_url: 'https://dev.users.niobi.co/api/pay/test',
          redirection_url: 'abc.co',
          logo: invoiceImage,
          country_id: country,
          currency: dataadd.Currancy,
        };
      } else {
        const amount = typeof dataadd?.Amount === 'string' ? dataadd.Amount : ''; // Convert to string if it's not already
        const parsedAmount = parseInt(amount.replaceAll(',', ''));
        body = {
          amount:parsedAmount,
          business_name: dataadd.Business_name,
          description: dataadd.Description,
          file_name:invoicefile,
          callback_url: 'https://dev.users.niobi.co/api/pay/test',
          redirection_url: 'abc.co',
          logo: invoiceImage,
          country_id: country,
          currency: dataadd.Currancy,
        };
      }

      if (!!fileSize && 1024 * 1024 <= fileSize) {
        setSubmitButton(true);
      } else {
        setSubmitButton(false);
        if (!!updetid) {
          setLoading(true);
          dispatch(
            paymentJourneyActions.PaymentLinkApiUpdateActive(
              body,
              updetid_LIST
            ) as any
          );
          const event = 'New Payment Link Updated';
          amplitude.getInstance().logEvent(event, eventProperties);
          posthog.capture(event, eventProperties);
          setTimeout(() => {
            setApiredusar_two(true);
          }, 3000);
        } else {
          setLoading(true);
          dispatch(paymentJourneyActions.paymentLinkApiCreate(body) as any);
          const event = 'New Payment Link Created';
          amplitude.getInstance().logEvent(event, eventProperties);
          posthog.capture(event, eventProperties);
          setTimeout(() => {
            setApiredusar(true);
          }, 3000);
        }
      }
    } else {
      setSubmitButton(true);
    }
  };
  React.useEffect(() => {
    if (apiredusar && dataList?.PAYMENT_LINK_API_CREATE_SUCCESS !== undefined) {
      setDATAPAGE(false);
      setApiredusar(false);
      setLoading(false);
      setLinkdata(dataList?.PAYMENT_LINK_API_CREATE_SUCCESS);
    }
  }, [apiredusar, dataList]);
  React.useEffect(() => {
    if (apiredusar_two && dataList?.PAYMENTLINKUPDATE_SUCCESS !== undefined) {
      setDATAPAGE(false);
      setLoading(false);
      setApiredusar_two(false);
      setLinkdata(dataList?.PAYMENTLINKUPDATE_SUCCESS);
    }
  }, [apiredusar_two, dataList]);
  React.useEffect(() => {
    if (getredusar && dataList?.PAYMENTLINKSHORTCODE_SUCCESS !== undefined) {
      setGetredusar(false);
      var dataUPDET = dataList?.PAYMENTLINKSHORTCODE_SUCCESS;
      setinvoicefile(dataUPDET?.file_name);
      setUpdetid_LIST(dataUPDET?.id);
      setCountry(dataUPDET.country_id);
      const findcurrencies: any = countryListApi.find(
        (data: any) => data.id == dataUPDET.country_id
      );
      setCurrencies(findcurrencies?.currencies);
      setDataadd({
        Business_name: dataUPDET.business_name,
        Upload_logo: dataUPDET.logo,
        Description: dataUPDET.item_name,
        Amount: dataUPDET?.amount == null ? 0 : dataUPDET?.amount,
        Currancy: dataUPDET.currency,
      });
    }
  }, [getredusar, dataList]);
  const onClose = () => {
    setDATAPAGE(true);
  };

  return (
    <>
      {datapage == true ? (
        <Grid container className="ClassName_Grid">
          <Grid item md={4} sm={12} xs={12}>
            <Button
              className="GOback_button"
              onClick={() => {
                navigate('/settings/Payment_Link');
              }}
            >
              <KeyboardBackspaceIcon /> Go Back
            </Button>
            <div className="input-form-wrapper dot">
              <label>Business name</label>
              <input
                name="First_name"
                type="text"
                value={dataadd?.Business_name}
                placeholder="Business name"
                onChange={(e: any) => {
                  setDataadd({
                    Business_name: e.target.value,
                    Upload_logo: dataadd.Upload_logo,
                    Description: dataadd.Description,
                    Amount: dataadd.Amount,
                    Currancy: dataadd.Currancy,
                  });
                }}
                style={{
                  background: '#fff',
                  border: '1px solid var(--Greys-Light-Grey-2, #DDD)',
                }}
                className={'text-field'}
              />
              {!dataadd?.Business_name && submitButton == true ? (
                <Typography className="Typography_error">
                  {valid?.Business_name}
                </Typography>
              ) : (
                ''
              )}
            </div>
            <div className="fast_button_and_input">
              <label></label>
              <Tooltip title="File size: Max 1MB. File-type: jpeg, png">
                <label className="complete-profile__upload  mb-0 ">
                  <div
                    id="afafafsdaFfsf"
                    className="complete-profile__upload__label"
                  >
                    <span className="complete-profile__upload__txt">
                      {invoicefile}
                    </span>
                    <UploadIcon />
                  </div>
                  <input
                    type="file"
                    name="invoice_record"
                    onChange={handleImage}
                  />
                  {1024 * 1024 <= fileSize && submitButton == true ? (
                    <div className="error-message">
                      File size: Max 1MB. File-type: jpeg, png
                    </div>
                  ) : null}
                </label>
              </Tooltip>
            </div>
            <div className="input-form-wrapper">
              <label>Country of payment collection</label>
              <Form.Select
                name="Country"
                style={{ background: '#fff', width: '100%' }}
                value={country}
                onChange={(e: any) => {
                  setCountry(e.target.value);
                  setCountry_List(e.target.value);
                  const findcurrencies: any = countryListApi.find(
                    (data: any) => data.id == e.target.value
                  );
                  setCurrencies(findcurrencies?.currencies);
                  setCountryNameget(findcurrencies?.name);
                }}
                className={'text-field'}
              >
                <option selected value="">
                  Select country
                </option>
                {countryListApi?.map((arrayElement: any, index: number) => {
                  return (
                    <option
                      value={arrayElement.id}
                      onClick={() => {}}
                      key={index + 1}
                    >
                      {arrayElement.name}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className="input-form-wrapper" style={{ margin: '10px 0px' }}>
              <label>Product description</label>
              <input
                name="First_name"
                type="text"
                placeholder="Description"
                value={dataadd.Description}
                onChange={(e: any) => {
                  setDataadd({
                    Business_name: dataadd.Business_name,
                    Upload_logo: dataadd.Upload_logo,
                    Description: e.target.value,
                    Amount: dataadd.Amount,
                    Currancy: dataadd.Currancy,
                  });
                }}
                style={{
                  background: '#fff',
                  border: '1px solid var(--Greys-Light-Grey-2, #DDD)',
                }}
                className={'text-field'}
              />
            </div>
            <div className="className_divmenu">
              <div style={{ width: '60%' }}>
                <div
                  className="input-form-wrapper"
                  style={{ width: '100%', margin: '10px 0px', padding: 0 }}
                >
                  <label>Amount</label>
                  <InputGroup
                    className="doc-input-wrapper input"
                    style={{ padding: 0 }}
                  >
                    <MaskedInput
                      mask={numberMask}
                      style={{ height: '100%' }}
                      className="doc-form-input form-control"
                      placeholder="Total"
                      value={dataadd?.Amount}
                      name="amount"
                      onChange={(e) => {
                        const kesValue = getKESFormate(e.target.value);
                        const obj = e;
                        setDataadd({
                          Business_name: dataadd.Business_name,
                          Upload_logo: dataadd.Upload_logo,
                          Description: dataadd.Description,
                          Amount: e.target.value,
                          Currancy: dataadd.Currancy,
                        });
                      }}
                      aria-describedby="basic-addon3"
                    />
                  </InputGroup>
                </div>
              </div>
              <div style={{ width: '40%' }}>
                <div
                  className="input-form-wrapper"
                  style={{
                    width: '91%',
                    margin: '10px 0px 10px 10px',
                    padding: 0,
                  }}
                >
                  <label></label>
                  <Form.Select
                    disabled={!!country ? false : true}
                    onChange={(e: any) => {
                      setDataadd({
                        Business_name: dataadd.Business_name,
                        Upload_logo: dataadd.Upload_logo,
                        Description: dataadd.Description,
                        Amount: dataadd.Amount,
                        Currancy: e.target.value,
                      });
                    }}
                    value={dataadd.Currancy}
                    name="Conversion_Currency"
                    className="select_box_kes"
                  >
                    <option selected value="">
                      Currency
                    </option>
                    {currencies?.map((arrayElement: any, index: number) => {
                      return (
                        <option value={arrayElement.currency} key={index + 1}>
                          {arrayElement.currency}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {!dataadd?.Currancy && submitButton == true ? (
                    <Typography className="Typography_error">
                      {valid?.Amount}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>

            <div>
              <Button
                className="payment_BUUTON"
                onClick={() => {
                  HandSubmit();
                }}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                {!!updetid ? 'Update' : 'Create'}
              </Button>
            </div>
          </Grid>
          <Grid item md={8} sm={12}>
            <div className="className_divGridhing">
              <Typography>Payment Link Preview</Typography>
            </div>
            <div className="main_div_detels">
              <Grid container>
                <Grid item md={5} xs={12} style={{ position: 'relative' }}>
                  <Preview data={dataadd} logo={invoiceImage} />
                </Grid>
                <Grid item md={7} xs={12}>
                  <div className="from_contean_data_setp2">
                    {pages == 1 ? (
                      <Detailspayment country={country} data={dataadd} />
                    ) : (
                      <Setp2 country={country_List} contryCode={contryCode} />
                    )}
                    <div className="button_clsssIconButton">
                      <IconButton
                        disabled={pages == 1 ? true : false}
                        onClick={() => {
                          setPages(pages - 1);
                        }}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <Typography>{pages} of 2</Typography>

                      <IconButton
                        disabled={pages == 2 ? true : false}
                        onClick={() => {
                          setPages(pages + 1);
                        }}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      ) : (
        <LinkPage data={linkDATA} updetid={updetid} onClose={onClose} />
      )}
    </>
  );
};
export default CeretePaymentLinkFrom;
const UploadIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.67773 14V16C1.67773 16.5304 1.88845 17.0391 2.26352 17.4142C2.63859 17.7893 3.1473 18 3.67773 18H15.6777C16.2082 18 16.7169 17.7893 17.0919 17.4142C17.467 17.0391 17.6777 16.5304 17.6777 16V14M4.67773 6L9.67773 1L14.6777 6M9.67773 1V13"
      stroke="#B8B8B8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
