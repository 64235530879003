export const counterpartsConstants = {
    GETCOUNTERPARTS_REQUEST: 'GETCOUNTERPARTS_REQUEST',
    GETCOUNTERPARTS_SUCCESS: 'GETCOUNTERPARTS_SUCCESS',
    GETCOUNTERPARTS_FROM_STORE: 'GETCOUNTERPARTS_FROM_STORE',
    GETCOUNTERPARTS_FAILURE: 'GETCOUNTERPARTS_FAILURE',

    GETCOUNTERPARTS_LIST_REQUEST: 'GETCOUNTERPARTS_LIST_REQUEST',
    GETCOUNTERPARTS_LIST_SUCCESS: 'GETCOUNTERPARTS_LIST_SUCCESS',
    GETCOUNTERPARTS_LIST_FAILURE: 'GETCOUNTERPARTS_LIST_FAILURE',

    GETBILL_LIST_REQUEST: 'GETBILL_LIST_REQUEST',
    GETBILL_LIST_SUCCESS: 'GETBILL_LIST_SUCCESS',
    GETBILL_LIST_FAILURE: 'GETBILL_LIST_FAILURE',

    GETBILL_STATUS_LIST_REQUEST: 'GETBILL_STATUS_LIST_REQUEST',
    GETBILL_STATUS_LIST_SUCCESS: 'GETBILL_STATUS_LIST_SUCCESS',
    GETBILL_STATUS_LIST_FAILURE: 'GETBILL_STATUS_LIST_FAILURE',

    GETUSERCOUNTERPART_REQUEST: 'GETUSERCOUNTERPART_REQUEST',
    GETUSERCOUNTERPART_SUCCESS: 'GETUSERCOUNTERPART_SUCCESS',
    GETUSERCOUNTERPART_FROM_STORE: 'GETUSERCOUNTERPART_FROM_STORE',
    GETUSERCOUNTERPART_FAILURE: 'GETUSERCOUNTERPART_FAILURE',

    GETUSERCOUNTERPART_EDIT_REQUEST: 'GETUSERCOUNTERPART_EDIT_REQUEST',
    GETUSERCOUNTERPART_EDIT_SUCCESS: 'GETUSERCOUNTERPART_EDIT_SUCCESS',
    GETUSERCOUNTERPART_EDIT_FAILURE: 'GETUSERCOUNTERPART_EDIT_FAILURE',

    EDIT_COUNTERPARTS_REQUEST: "EDIT_COUNTERPARTS_REQUEST",
    EDIT_COUNTERPARTS_SUCCESS: "EDIT_COUNTERPARTS_SUCCESS",
    EDIT_COUNTERPARTS_FAILURE: "EDIT_COUNTERPARTS_FAILURE",

    EDIT_INVOICE_REQUEST: "EDIT_INVOICE_REQUEST",
    EDIT_INVOICE_SUCCESS: "EDIT_INVOICE_SUCCESS",
    EDIT_INVOICE_FAILURE: "EDIT_INVOICE_FAILURE",
    // getCustomerDropDoun
    GETCUSTOMER_DROPDOUN_REQUEST: 'GETCUSTOMER_DROPDOUN_REQUEST',
    GETCUSTOMER_DROPDOUN_SUCCESS: 'GETCUSTOMER_DROPDOUN_SUCCESS',
    GETCUSTOMER_DROPDOUN_FAILURE: 'GETCUSTOMER_DROPDOUN_FAILURE',

    GETTAGS_REQUEST: 'GETTAGS_REQUEST',
    GETTAGS_SUCCESS: 'GETTAGS_SUCCESS',
    GETTAGS_FROM_STORE: 'GETTAGS_FROM_STORE',
    GETTAGS_FAILURE: 'GETTAGS_FAILURE',

    GETALLVAT_REQUEST: 'GETALLVAT_REQUEST',
    GETALLVAT_SUCCESS: 'GETALLVAT_SUCCESS',
    GETALLVAT_FAILURE: 'GETALLVAT_FAILURE',

    GETBULK_PAYMENT_PAYABLES_REQUEST: 'GETBULK_PAYMENT_PAYABLES_REQUEST',
    GETBULK_PAYMENT_PAYABLES_SUCCESS: 'GETBULK_PAYMENT_PAYABLES_SUCCESS',
    GETBULK_PAYMENT_PAYABLES_FAILURE: 'GETBULK_PAYMENT_PAYABLES_FAILURE',

    GETTAGS_BUDGET_REQUEST: 'GETTAGS_BUDGET_REQUEST',
    GETTAGS_BUDGET_SUCCESS: 'GETTAGS_BUDGET_SUCCESS',
    GETTAGS_BUDGET_FAILURE: 'GETTAGS_BUDGET_FAILURE',

    DELETE_PAYEE_REQUEST: "DELETE_PAYEE_REQUEST",
    DELETE_PAYEE_SUCCESS: "DELETE_PAYEE_SUCCESS",
    DELETE_PAYEE_FAILURE: "DELETE_PAYEE_FAILURE",
    // delete-customer
    DELETE_COSTOMER_REQUEST: "DELETE_COSTOMER_REQUEST",
    DELETE_COSTOMER_SUCCESS: "DELETE_COSTOMER_SUCCESS",
    DELETE_COSTOMER_FAILURE: "DELETE_COSTOMER_FAILURE",
    // getCustomerById
    GET_CUSTOMER_BY_ID_REQUEST: "GET_CUSTOMER_BY_ID_REQUEST",
    GET_CUSTOMER_BY_ID_SUCCESS: "GET_CUSTOMER_BY_ID_SUCCESS",
    GET_CUSTOMER_BY_ID_FAILURE: "GET_CUSTOMER_BY_ID_FAILURE",

    GET_CUSTOMER_REQUEST: "GET_CUSTOMER_REQUEST",
    GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
    GET_CUSTOMER_FAILURE: "GET_CUSTOMER_FAILURE",

    GET_COUNTERPARTS_SUCCESS: 'GET_COUNTERPARTS_SUCCESS',
    GET_TAGS_SUCCESS: 'GET_TAGS_SUCCESS',
    GET_PENDING_PAYABLES_REQUEST: 'GET_PENDING_PAYABLES_REQUEST',
    GET_PENDING_PAYABLES_SUCCESS: 'GET_PENDING_PAYABLES_SUCCESS',
    GET_PENDING_PAYABLES_FAILURE: 'GET_PENDING_PAYABLES_FAILURE',

    UPDATE_BULK_PAYMENT_PAYABLES_REQUEST: 'UPDATE_BULK_PAYMENT_PAYABLES_REQUEST',
    UPDATE_BULK_PAYMENT_PAYABLES_SUCCESS: 'UPDATE_BULK_PAYMENT_PAYABLES_SUCCESS',
    UPDATE_BULK_PAYMENT_PAYABLES_FAILURE: 'UPDATE_BULK_PAYMENT_PAYABLES_FAILURE',

    REJECT_BULK_PAYMENT_PAYABLES_REQUEST: 'REJECT_BULK_PAYMENT_PAYABLES_REQUEST',
    REJECT_BULK_PAYMENT_PAYABLES_SUCCESS: 'REJECT_BULK_PAYMENT_PAYABLES_SUCCESS',
    REJECT_BULK_PAYMENT_PAYABLES_FAILURE: 'REJECT_BULK_PAYMENT_PAYABLES_FAILURE',

    UPDATE_PAYABLES_REQUEST: 'UPDATE_PAYABLES_REQUEST',
    UPDATE_PAYABLES_SUCCESS: 'UPDATE_PAYABLES_SUCCESS',
    UPDATE_PAYABLES_FAILURE: 'UPDATE_PAYABLES_FAILURE',
    // cereteCategories
    CERETE_CATEGORIES_REQUEST: "CERETE_CATEGORIES_REQUEST",
    CERETE_CATEGORIES_SUCCESS: "CERETE_CATEGORIES_SUCCESS",
    CERETE_CATEGORIES_FAILURE: "CERETE_CATEGORIES_FAILURE",

    CERETE_COUNTERPARTS_REQUEST: "CERETE_COUNTERPARTS_REQUEST",
    CERETE_COUNTERPARTS_SUCCESS: "CERETE_COUNTERPARTS_SUCCESS",
    CERETE_COUNTERPARTS_FAILURE: "CERETE_COUNTERPARTS_FAILURE",

    // CustomersCreate
    CUSTOMERS_CREATE_REQUEST: "CUSTOMERS_CREATE_REQUEST",
    CUSTOMERS_CREATE_SUCCESS: "CUSTOMERS_CREATE_SUCCESS",
    CUSTOMERS_CREATE_FAILURE: "CUSTOMERS_CREATE_FAILURE",

    CUSTOMERS_EDIT_REQUEST: "CUSTOMERS_EDIT_REQUEST",
    CUSTOMERS_EDIT_SUCCESS: "CUSTOMERS_EDIT_SUCCESS",
    CUSTOMERS_EDIT_FAILURE: "CUSTOMERS_EDIT_FAILURE",

    SUBMIT_PAYABLES_REQUEST: 'SUBMIT_PAYABLES_REQUEST',
    SUBMIT_PAYABLES_SUCCESS: 'SUBMIT_PAYABLES_SUCCESS',
    SUBMIT_PAYABLES_FAILURE: 'SUBMIT_PAYABLES_FAILURE',
// AllCounterparts
    GET_COUNTERPARTS_REQUEST: 'GET_COUNTERPARTS_REQUEST',
    GET_COUNTERPARTS_SUCCESS: 'GET_COUNTERPARTS_SUCCESS',
    GET_COUNTERPARTS_FAILURE: 'GET_COUNTERPARTS_FAILURE',

    UPDATE_CATEGORIES_REQUEST:"UPDATE_CATEGORIES_REQUEST",
    UPDATE_CATEGORIES_SUCCESS:"UPDATE_CATEGORIES_SUCCESS",
    UPDATE_CATEGORIES_FAILURE:"UPDATE_CATEGORIES_FAILURE"

}