import styles from './Create_your_invoice.module.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Box, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { Col, Form, Button, InputGroup } from "react-bootstrap";
import { CountryDropdown } from 'react-country-region-selector';
import Preview_nodata from './components/Preview/Preview_nodata';
import Preview_fils from './components/Preview_fils/Preview_fils';
import { useDispatch, useSelector } from "react-redux";
import { selectAllBalanceLIstData } from "../../../../../_reducers/balance.reducer";
import { balanceActions } from "../../../../../_actions/balance.actions";
import Checkbox from '@mui/material/Checkbox';
import { selectAllCounterpartsListData } from "../../../../../_reducers/counterparts.reducer";
import { counterpartsActions } from "../../../../../_actions/counterparts.actions";
import { useSearchParams } from "react-router-dom";
import { dashboardActions } from "../../../../../_actions";
import {
    getBalanceReducer,
    getBillReducer,
    selectAllData,
} from "../../../../../_reducers/dashboard.reducer";
import { getUser, isUserAuthenticated, authHeader } from '../../../../../_helpers';
import countryList from 'react-select-country-list';
import moment from 'moment';

const Create_your_invoiceIn = (props: any) => {
    const user = getUser()?.data ?? '';

    const [payload, setPayload] = useState<any>({
        Invoice_Name: "",
        Upload_img: "",
        company_info_Name: user?.entity?.name,
        company_info_Title: user?.name,
        company_info_Email: user?.email,
        company_info_Mobile: user?.mobile,
        company_info_Address: user?.entity?.street_address,
        company_info_Country: user?.entity?.country,
        Type: "individual",
        Receiver_info_First_name: "",
        Receiver_info_Last_name: "",
        Receiver_info_Title: "",
        Receiver_info_Email: "",
        Receiver_info_Mobile: "",
        Receiver_info_Address: "",
        Receiver_info_Country: "",

    })
    const dispatch = useDispatch();
    const [textrea, setTextrea] = useState('')
    const [bankDetailsRender_two, setBankDetailsRender_two] = useState(true);
    const [currencies, setCurrencies] = useState([])
    const [render, setRender] = useState(true);
    const [invoiceImage, setinvoiceImage] = useState<any>('');
    const [searchParams, setSearchParams] = useSearchParams();

    // const[invoice,setInvoice]=useState()
    const [ErrorMessage, setErrorMessage] = useState({
        Invoice_Name: "Please enter the invoice name.",
        Upload_img: "",
        company_info_Name: "Please enter the company name.",
        company_info_Sender: "Please enter the sender name.",
        company_info_Receiver: "Please enter the receiver name.",

        company_info_Email: "Please enter the email.",
        company_info_Address: "Please enter the address.",
        company_info_Mobile: "Please enter the mobile number.",
        company_info_Country: "Please select the country.",
        Invoice: "Please enter the invoice number.",
        Date_issued: "Please select the date issued.",
        Due_date: "Please select the due date.",
        Item: "Please enter the item.",
        Currency: "Please select the currency",
        Quantity: "Please enter the quantity",
        Price: "Please enter the price",
        description: ""
    })
    const [itemsbill, setItemsbill] = useState([{
        Item: "",
        Quantity: "",
        Price: "",
        total: 0,
        description: "",
        description_data: false
    }])
    const [bill_items, setBill_items] = useState([{
        title: "",
        quantity: "",
        price: "",
        total: 0,
        description: "",
    }])
    console.log(invoiceImage, "invoiceImage");
    const [validsan, setValidsan] = useState(false)
    const [Discount_value, setDisacaunt_value] = useState("")
    const [disacaunt_type, setDisacaunt_type] = useState([{
        name: "Percentage"
    },
    {
        name: "Normal"
    }])
    const [counterpartsListRender_two_data, setCounterpartsListRender_two_data] = useState(true);
    const [footerdata, setFooterdata] = useState({
        totel: 0,
        textrea: "",
        Tax: 0,
        Discount: 0,
        pagetotel: 0,
        includes_tax: false,
        tex_totel: 0,
        Discount_value: 0,
        includes_Discount: false,
        Discount_type: 'Normal',
    })
    const [vatall, setVatall] = useState([])
    const [vatType, setVatType] = useState('')
    const DashboardData = useSelector((state) => selectAllData(state));
    const options = useMemo(() => countryList().getData(), []);

    console.log(footerdata, "footerdatafooterdata");
    const onCancel = () => {
        setValidsan(true)
    }
    const [info, setInfo] = useState({
        Invoice: "",
        Date_issued: "",
        Due_date: "",
        Currency: ""
    })
    const [validesanpayload, setValidesanpayload] = useState(false)
    const [validesannodata, setValidesannodata] = useState(false)
    const [idPage, setIdPage] = useState("")
    const [ReceiverHide, setReceiverHide] = useState(false)
    const [receiver_info, setReceiver_info] = useState({
        name: '',
        title: '',
        email: '',
        mobile: '',
        address: '',
        country: '',
    })
    const [ListdoupDoun, setListdoupDoun] = useState([])
    const [listRender, setListRender] = useState(false)
    const [customer_nameSender, setCustomer_nameSender] = useState("")
    const [customer_nameReceiverr, setCustomer_nameReceiver] = useState("")
    const [selectReceiver, setselectReceiver] = useState<any>()
    // const[selectR]
    const data = useSelector((state) => selectAllBalanceLIstData(state));
    const counterpartsListData = useSelector((state) =>
        selectAllCounterpartsListData(state)
    );
    const [RedusarAPI, setRedusarAPI] = useState(true)
    const [redusar, setRedusar] = useState(false)
    const [loader, setLoader] = useState(false)

    console.log(validesanpayload, "validesanpayload");

    useEffect(() => {
        const popup = searchParams;
        console.log(popup, "popuppopup");

    }, []);
    useEffect(() => {
        if (render) {
            setRender(false);
            dispatch(counterpartsActions.getAllvat() as any)
            dispatch(balanceActions.getTopUpDetails_doller() as any);
        }
    }, [render]);
    console.log((!!payload &&
        ReceiverHide == false &&
        payload?.company_info_Address &&
        payload?.company_info_Email &&
        payload?.company_info_Title &&
        payload?.company_info_Name &&
        payload?.company_info &&
        !!customer_nameReceiverr &&
        info?.Currency &&
        info?.Date_issued &&
        info?.Due_date &&
        info?.Invoice),
        customer_nameReceiverr,payload,info,validesanpayload, "szdfsffsgdfx");

    useEffect(() => {
        const findfast = itemsbill.find((o: any) => o.Item == '')
        const findtwo = itemsbill.find((o: any) => o.Quantity == '')
        const findthdee = itemsbill.find((o: any) => o.Price == '')
        console.log(findfast, findtwo, findthdee, "findthdee");

        if (!!payload &&
            payload?.Receiver_info_Address &&
            payload?.Receiver_info_Email &&
            payload?.Receiver_info_Title &&
            payload?.Receiver_info_First_name &&
            payload?.Receiver_info_Last_name &&
            payload?.Type == "individual" &&
            payload?.Receiver_info_Mobile &&
            payload?.company_info_Address &&
            payload?.company_info_Email &&
            payload?.company_info_Title &&
            payload?.company_info_Name &&
            payload?.company_info_Mobile &&
            info?.Currency &&
            info?.Date_issued &&
            info?.Due_date &&
            info?.Invoice &&
            findfast == undefined) {
            setValidesanpayload(true)
        } else if (!!payload &&
            payload?.Receiver_info_Address &&
            payload?.Receiver_info_Email &&
            payload?.Receiver_info_Title &&
            payload?.Receiver_info_First_name &&
            payload?.Type == "organization" &&
            payload?.Receiver_info_Mobile &&
            payload?.company_info_Address &&
            payload?.company_info_Email &&
            payload?.company_info_Title &&
            payload?.company_info_Name &&
            payload?.company_info_Mobile &&
            info?.Currency &&
            info?.Date_issued &&
            info?.Due_date &&
            info?.Invoice &&
            findfast == undefined) {
            setValidesanpayload(true)
        } else if (!!payload &&
            ReceiverHide == false &&
            payload?.company_info_Address &&
            payload?.company_info_Email &&
            payload?.company_info_Title &&
            payload?.company_info_Name &&
            payload?.company_info &&
            !!customer_nameReceiverr &&
            info?.Currency &&
            info?.Date_issued &&
            info?.Due_date &&
            info?.Invoice &&
            findfast == undefined) {
            setValidesanpayload(true)
        } else {
            setValidesanpayload(false)
        }

        if (!!payload &&
            payload?.Invoice_Name ||
            payload?.Receiver_info_Address ||
            payload?.Receiver_info_Country ||
            payload?.Receiver_info_Email ||
            payload?.Receiver_info_Title ||
            payload?.Receiver_info_First_name ||
            payload?.Receiver_info_Last_name ||
            payload?.Receiver_info_Mobile ||
            payload?.Upload_img ||
            payload?.company_info_Address ||
            payload?.company_info_Country ||
            payload?.company_info_Email ||
            payload?.company_info_Title ||
            payload?.company_info_Name ||
            payload?.company_info_Mobile ||
            info?.Currency ||
            info?.Date_issued ||
            info?.Due_date ||
            info?.Invoice ||
            findfast == undefined ||
            findtwo == undefined ||
            findthdee == undefined
        ) {
            setValidesannodata(true)
        } else {
            setValidesannodata(false)
        }

    }, [payload, itemsbill, info, customer_nameReceiverr, ReceiverHide, invoiceImage]);
    console.log(validesannodata, "validesannodata");


   
    console.log(itemsbill, "itemsbillitemsbill");

    // const[description_,setdescription]=useState()
    console.log(payload, "payloadpayload");
    const handleAddClick = () => {
        setItemsbill([...itemsbill, {
            Item: "",
            Quantity: "",
            Price: "",
            total: 0,
            description: "",
            description_data: false
        }])
        setBill_items([...bill_items, {
            title: "",
            quantity: "",
            price: "",
            total: 0,
            description: "",
        }])
    }
    const handleRemoveClick = (index: any) => {
        console.log(index);
        const list = [...itemsbill];
        list.splice(index, 1);
        setItemsbill(list);
        const removedata = [...bill_items]
        removedata.splice(index, 1)
        setBill_items(removedata)
    }
    useEffect(() => {
        if (isUserAuthenticated() && RedusarAPI) {
            dispatch(counterpartsActions.getCustomerDropDoun() as any);
            setRedusarAPI(false)
            setTimeout(() => {
                setListRender(true)
            }, 4000)
        }
    }, [])
    useEffect(() => {
        const popup: any = searchParams.get('id');
        setIdPage(popup)
    }, [searchParams])
    useEffect(() => {
        if (counterpartsListData?.GETCUSTOMER_DROPDOUN_SUCCESS !== undefined && listRender) {
            setListRender(false)
            setListdoupDoun(counterpartsListData?.GETCUSTOMER_DROPDOUN_SUCCESS)
        }
    }, [listRender, counterpartsListData])
    useEffect(() => {
        if (!!idPage) {
            dispatch(dashboardActions.getInvoicedetels(idPage) as any);
            setLoader(true)
            setTimeout(() => (
                setRedusar(true)
            ), 3000)
        }
    }, [idPage])

    useEffect(() => {
        if (redusar && DashboardData?.GET_INVOICE_BY_ID_SUCCESS !== undefined) {
            setRedusar(false)
            setLoader(false)
            var ListData = DashboardData?.GET_INVOICE_BY_ID_SUCCESS.data
            const findcurrencies: any = options.find(
                (data: any) => data.value == ListData.customer.contact_country
            );
            console.log(ListData, moment(ListData.due_date).format('YYYY-MM-DD'), "ListDataListData123");
            setInfo({
                Invoice: ListData?.invoice_name,
                Date_issued: moment(ListData.issued_at).format('YYYY-MM-DD'),
                Due_date: moment(ListData.due_date).format('YYYY-MM-DD'),
                Currency: ListData.currency
            })
            if (!!ListData?.customer?.id) {
                setReceiverHide(false)
                setCustomer_nameReceiver(ListData?.customer?.id)
            } else {
                setReceiverHide(true)

            }
            var ArrayObject: any = []
            var ArrayItem: any = []
            for (let index = 0; index < ListData?.items.length; index++) {
                const element = ListData?.items[index];
                var object = {
                    title: element.title,
                    quantity: Number(element.quantity),
                    price: Number(element.price),
                    total: Number(element.total),
                    description: element.description,
                }
                var resObje = {
                    Item: element.title,
                    Quantity: Number(element.quantity),
                    Price: Number(element.price),
                    total: Number(element.total),
                    description: element.description,
                    description_data: false
                }
                ArrayItem.push(resObje)
                ArrayObject.push(object)
            }
            setselectReceiver(ListData.customer)
            setItemsbill(ArrayItem)
            setBill_items(ArrayObject)
            console.log(ListData.total,"ListData.totelsfgfgg");
            
            setFooterdata({
                totel: Number(ListData?.sub_total),
                textrea: ListData?.memo,
                Tax: Number(ListData.tax_percentage),
                Discount_type: ListData?.discount_type,
                Discount: ListData?.discount,
                pagetotel: Number(ListData.total),
                Discount_value: Number(ListData?.discount_value),
                includes_tax: footerdata.includes_tax,
                tex_totel: ListData.tax,
                includes_Discount: footerdata.includes_Discount
            })
            setinvoiceImage(ListData?.logo)
            if (ListData?.customer?.customer_type == "individual") {
                setPayload({
                    Invoice_Name: ListData?.invoice_name,
                    Upload_img: ListData?.logo,
                    company_info_Name: user?.entity?.name,
                    company_info_Title: user?.name,
                    company_info_Email: user?.email,
                    company_info_Mobile: user?.mobile,
                    company_info_Address: user?.entity?.street_address,
                    company_info_Country: user?.entity?.country,
                    Type: ListData.customer.customer_type,
                    Receiver_info_First_name: ListData.customer.contact_first_name,
                    Receiver_info_Last_name: ListData.customer.contact_last_name,
                    Receiver_info_Title: ListData.customer.name,
                    Receiver_info_Email: ListData.customer.contact_email,
                    Receiver_info_Mobile: ListData.customer.contact_phone,
                    Receiver_info_Address: ListData.customer.contact_line1,
                    Receiver_info_Country: findcurrencies?.label,
                })
            } else {
                setPayload({
                    Invoice_Name: ListData?.invoice_name,
                    Upload_img: ListData?.logo,
                    company_info_Name: user?.entity?.name,
                    company_info_Title: user?.name,
                    company_info_Email: user?.email,
                    company_info_Mobile: user?.mobile,
                    company_info_Address: user?.entity?.street_address,
                    company_info_Country: user?.entity?.country,
                    Type: ListData.customer.customer_type,
                    Receiver_info_First_name: ListData.customer.name,
                    Receiver_info_Last_name: "",
                    Receiver_info_Title: ListData.customer.name,
                    Receiver_info_Email: ListData.customer.contact_email,
                    Receiver_info_Mobile: ListData.customer.contact_phone,
                    Receiver_info_Address: ListData.customer.contact_line1,
                    Receiver_info_Country: findcurrencies?.label,
                })
            }
            //   setDatadeteld(data?.GET_INVOICE_BY_ID_SUCCESS)
            //   setLoader(false)

        }
    }, [redusar, DashboardData])
    console.log(((Number(footerdata.totel) - Number(footerdata.Discount_value)) - ((Number(footerdata.totel) - Number(footerdata.Discount_value)) / (1 + Number(footerdata.Tax) / 100))).toLocaleString(
        'en',
        { minimumFractionDigits: 2 }
    ),Number(footerdata.totel),Number(footerdata.Discount_value),Number(footerdata.Tax),"sfdaSFFffffff");
    
    useEffect(() => {
        if (bankDetailsRender_two && data?.GETCURRENCIES_SUCCESS !== undefined) {
            setBankDetailsRender_two(false);
            console.log(data.GETCURRENCIES_SUCCESS, 'data.GETCURRENCIES_SUCCESS');
            setCurrencies(data.GETCURRENCIES_SUCCESS)
            // setBankDetails(data.GETCURRENCIES_SUCCESS);
            // setBankType(data.GET_BANK_DETAILS_ID_SUCCESS.data.methods)
        }
    }, [data, bankDetailsRender_two]);
    useEffect(() => {
        if (counterpartsListRender_two_data && counterpartsListData?.GETALLVAT_SUCCESS !== undefined) {
            setCounterpartsListRender_two_data(false);
            setVatall(counterpartsListData?.GETALLVAT_SUCCESS);
        }
    }, [counterpartsListData, counterpartsListRender_two_data]);
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    console.log(idPage,!idPage,"sAffasfsff");
    
    useEffect(() => {
        // if(!idPage){
        let datalist: any = 0;
        for (let index = 0; index < itemsbill.length; index++) {
            const element = itemsbill[index];
            console.log(element, 'elementelementhhh');
            datalist += +element.total
        }
        // console.log((Number(datalist) * Number(footerdata.Tax)),footerdata.Tax,"aFsAFFfaffff");
        
        setFooterdata({
            totel: datalist,
            textrea: footerdata.textrea,
            Tax: footerdata.Tax,
            Discount: footerdata.Discount,
            pagetotel: ((Number(datalist) - Number(footerdata.Discount_value)) + ((Number(datalist) - Number(footerdata.Discount_value)) - ((Number(datalist) - Number(footerdata.Discount_value)) / (1 + (Number(footerdata.Tax)) / 100)))),
            includes_tax: footerdata.includes_tax,
            tex_totel:((Number(datalist) - Number(footerdata.Discount_value)) - ((Number(datalist) - Number(footerdata.Discount_value)) / (1 + (Number(footerdata.Tax)) / 100))),
            Discount_value: footerdata.Discount_value,
            includes_Discount: footerdata.includes_Discount,
            Discount_type: footerdata.Discount_type,
        })
    // }
    }, [itemsbill,footerdata])
    const getBase64 = (file: any, cb: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    // console.log(((footerdata.pagetotel)footerdata.pagetotel) - (footerdata.Discount)) * (Number(footerdata.Tax) / 100)),'djjdjdjjdjdjd');

    // setPayload({
    //     Invoice_Name: payload.Invoice_Name,
    //     Upload_img: result,
    //     company_info_Title: payload.company_info_Title,
    //     company_info_Email: payload.company_info_Email,
    //     company_info_Address: payload.company_info_Address,
    //     company_info_Country: payload.company_info_Country,
    //     Receiver_info_Title: payload.Receiver_info_Title,
    //     Receiver_info_Email: payload.Receiver_info_Email,
    //     Receiver_info_Address: payload.Receiver_info_Address,
    //     Receiver_info_Country: payload.Receiver_info_Country,
    // })
    const handleImage = (e: any) => {
        setPayload({
            Invoice_Name: payload.Invoice_Name,
            Upload_img: e.target.value,
            company_info_Name: payload.company_info_Name,
            company_info_Title: payload.company_info_Title,
            company_info_Email: payload.company_info_Email,
            company_info_Mobile: payload.company_info_Mobile,
            company_info_Address: payload.company_info_Address,
            company_info_Country: payload.company_info_Country,
            Type: payload.Type,
            Receiver_info_First_name: payload.Receiver_info_First_name,
            Receiver_info_Last_name: payload.Receiver_info_Last_name,
             Receiver_info_Title: payload.Receiver_info_Title,
            Receiver_info_Email: payload.Receiver_info_Email,
            Receiver_info_Mobile: payload.Receiver_info_Mobile,

            Receiver_info_Address: payload.Receiver_info_Address,
            Receiver_info_Country: payload.Receiver_info_Country,
        })
        // setinvoicefile(e.target.files[0].name);
        getBase64(e.target.files[0], (result: any) => {
            setinvoiceImage(result);
            // console.log(result,e,"result123");


        });
    }
    console.log(((Number(footerdata.totel) - Number(footerdata.Discount_value)) / (1 + Number(footerdata.Tax) / 100)), (1 + Number(footerdata.Tax) / 100), (Number(footerdata.totel) - Number(footerdata.Discount_value)), "sadaDdaDDd");

    return (
        <>
            <ThemeProvider theme={theme}>
                {loader && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                    <CircularProgress color="success" />
                </Box>}
                <Grid container className={styles.container_grid}>

                    <Grid item md={12}>
                        <Typography className={styles.heding_typography}>Create your invoice</Typography>
                        <Typography className={styles.typo_heding_footer}>Fill in the fields, preview your invoice and get it emailed directly to your customers.</Typography>
                    </Grid>
                    <Grid container
                        spacing={3}
                    >
                        <Grid item md={8}>
                            <div className={styles.two_grid_inputt}>
                                <div id={styles.id_contean} className="input-form-wrapper dot">
                                    <Typography className={styles.Typography_companyinfo} style={{ paddingTop: "35px" }}>INVOICE </Typography>

                                    {/* <label>Invoice Name</label>
                                    <InputGroup className="doc-input-wrapper">
                                        <Form.Control
                                            className={

                                                'doc-form-input test valid'}
                                            placeholder="Invoice Name"
                                            value={payload.Invoice_Name}
                                            name='name'
                                            type="text"
                                            
                                            onChange={(e: any) => {

                                                setPayload({
                                                    Invoice_Name: e.target.value,
                                                    Upload_img: payload.Upload_img,
                                                    company_info_Name: payload.company_info_Name,
                                                    company_info_Title: payload.company_info_Title,
                                                    company_info_Email: payload.company_info_Email,
                                                    company_info_Address: payload.company_info_Address,
                                                    company_info_Country: payload.company_info_Country,
                                                    Receiver_info_Name: payload.Receiver_info_Name,
                                                    Receiver_info_Title: payload.Receiver_info_Title,
                                                    Receiver_info_Email: payload.Receiver_info_Email,
                                                    Receiver_info_Address: payload.Receiver_info_Address,
                                                    Receiver_info_Country: payload.Receiver_info_Country,
                                                })
                                            }}
                                        />
                                    </InputGroup> */}
                                    {/* {!payload.Invoice_Name && validsan == true ? (
                                        <div className="error-message">{ErrorMessage.Invoice_Name}</div>
                                    ) : null} */}
                                </div>
                                <label id={styles.id_contean} className='complete-profile__upload mb-0' style={{ paddingTop: "41px" }}>
                                    <div id='afafafsdaFfsf' className='complete-profile__upload__label'
                                    // style={{ cursor: 'not-allowed' }}
                                    >
                                        <div>
                                            <UploadLogo />
                                            <span className='complete-profile__upload__txt' style={{ paddingLeft: "10px" }}>
                                                {payload?.Upload_img ? payload.Upload_img : "Upload logo"}</span>
                                        </div>
                                        <Upload_svg />

                                    </div>
                                    <input type="file" name='invoice_record'
                                        // disabled
                                        onChange={(e: any) => {
                                            console.log(e.target, "safffffff");
                                            handleImage(e)

                                        }}
                                    // className={touched.invoice_record && errors.invoice_record ? "  error" : ''}

                                    //onChange={handleChange}
                                    // onBlur={handleBlur}
                                    // value={payload.Upload_img}
                                    />
                                    {!payload.Upload_img && validsan == true ? (
                                        <div className="error-message">{ErrorMessage.Upload_img}</div>
                                    ) : null}
                                </label>
                            </div>
                            <div className={styles.div_buttomborder}>

                            </div>
                            <Typography className={styles.Typography_companyinfo} style={{ paddingTop: "15px" }}>Invoice Details</Typography>

                            <div className={styles.contenar_typo_left}>

                                <div className={styles.fast_contean} style={{ paddingTop: "0px" }}>
                                    <div className="input-form-wrapper dot">
                                        <label>Invoice #</label>
                                        <div className={styles.selext_inputbox}>
                                            <div style={{ width: "65%" }}>
                                                <InputGroup className="doc-input-wrapper">
                                                    <Form.Control
                                                        className={

                                                            'doc-form-input test valid'}
                                                        placeholder="Invoice #"
                                                        value={info.Invoice}
                                                        name='name'
                                                        type="text"
                                                        onChange={(e: any) => {
                                                            setInfo({
                                                                Invoice: e.target.value,
                                                                Date_issued: info.Date_issued,
                                                                Due_date: info.Due_date,
                                                                Currency: info.Currency
                                                            })
                                                        }}
                                                    />

                                                </InputGroup>
                                                {!info.Invoice && validsan == true ? (
                                                    <div className="error-message">{ErrorMessage.Invoice}</div>
                                                ) : null}
                                            </div>
                                            <div style={{ width: "33%" }}>
                                                <Form.Select
                                                    style={{ width: "100%" }}
                                                    onChange={(e: any) => {
                                                        setInfo({
                                                            Invoice: info.Invoice,
                                                            Date_issued: info.Date_issued,
                                                            Due_date: info.Due_date,
                                                            Currency: e.target.value
                                                        })
                                                    }}
                                                    value={info.Currency}
                                                    name="Conversion_Currency"
                                                    className={styles.select_box_kes}
                                                >
                                                    <option selected value="">Currency</option>
                                                    {currencies?.map((arrayElement: any, index: number) => {
                                                        return (
                                                            <option value={arrayElement.title} key={index + 1}>{arrayElement.title}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                {!info.Currency && validsan == true ? (
                                                    <div className="error-message">{ErrorMessage.Currency}</div>
                                                ) : null}
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className={styles.two_contendata} style={{ paddingTop: "0px" }}>
                                    <div className={styles.two_input_dataa}>
                                        <div id={styles.in_haf_cambain} className="input-form-wrapper dot">
                                            <label>Date issued</label>
                                            <input type='date'
                                                className="doc-date doc-form-input form-control"
                                                value={info.Date_issued}
                                                name='invoice_date'
                                                onChange={(e: any) => {
                                                    console.log(e.target.value, "saAFsffff");

                                                    setInfo({
                                                        Invoice: info.Invoice,
                                                        Date_issued: e.target.value,
                                                        Due_date: info.Due_date,
                                                        Currency: info.Currency
                                                    })
                                                }}
                                            />
                                            {!info.Date_issued && validsan == true ? (
                                                <div className="error-message">{ErrorMessage.Date_issued}</div>
                                            ) : null}
                                        </div>
                                        <div id={styles.in_haf_cambain} className="input-form-wrapper dot">
                                            <label>Due date</label>
                                            <input type='date'
                                                className="doc-date doc-form-input form-control"
                                                value={info.Due_date}
                                                name='invoice_date'
                                                onChange={(e: any) => {
                                                    setInfo({
                                                        Invoice: info.Invoice,
                                                        Date_issued: info.Date_issued,
                                                        Due_date: e.target.value,
                                                        Currency: info.Currency
                                                    })
                                                }}
                                            />
                                            {!info.Due_date && validsan == true ? (
                                                <div className="error-message">{ErrorMessage.Due_date}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className={styles.contenar_typo_left}>

                                <div className={styles.fast_contean}>
                                    <Typography className={styles.Typography_companyinfo}>Sender’s Information </Typography>
                                    <div className="input-form-wrapper dot">
                                        <label>Sender’s name</label>
                                        <InputGroup className="doc-input-wrapper">
                                            <Form.Control
                                                className={

                                                    'doc-form-input test valid'}
                                                placeholder="Sender’s name"
                                                disabled
                                                value={payload.company_info_Title}
                                                name='name'
                                                type="text"
                                                onChange={(e: any) => {
                                                    setPayload({
                                                        Invoice_Name: payload.Invoice_Name,
                                                        Upload_img: payload.Upload_img,
                                                        company_info_Name: payload.company_info_Name,
                                                        company_info_Title: e.target.value,
                                                        company_info_Email: payload.company_info_Email,
                                                        company_info_Mobile: payload.company_info_Mobile,

                                                        company_info_Address: payload.company_info_Address,
                                                        company_info_Country: payload.company_info_Country,
                                                        Type: payload.Type,
                                                        Receiver_info_First_name: payload.Receiver_info_First_name,
                                                        Receiver_info_Last_name: payload.Receiver_info_Last_name,
                                                         Receiver_info_Title: payload.Receiver_info_Title,
                                                        Receiver_info_Email: payload.Receiver_info_Email,
                                                        Receiver_info_Mobile: payload.Receiver_info_Mobile,

                                                        Receiver_info_Address: payload.Receiver_info_Address,
                                                        Receiver_info_Country: payload.Receiver_info_Country,
                                                    })
                                                }}
                                            />
                                        </InputGroup>
                                        {!payload.company_info_Title && validsan == true ? (
                                            <div className="error-message">{ErrorMessage.company_info_Sender}</div>
                                        ) : null}
                                    </div>
                                    <div className="input-form-wrapper dot">
                                        <label>Company name</label>
                                        <InputGroup className="doc-input-wrapper">
                                            <Form.Control
                                                className={

                                                    'doc-form-input test valid'}
                                                placeholder="Company name"
                                                disabled
                                                value={payload.company_info_Name}
                                                name='name'
                                                type="text"
                                                onChange={(e: any) => {
                                                    setPayload({
                                                        Invoice_Name: payload.Invoice_Name,
                                                        Upload_img: payload.Upload_img,
                                                        company_info_Name: e.target.value,
                                                        company_info_Title: payload.company_info_Title,
                                                        company_info_Email: payload.company_info_Email,
                                                        company_info_Mobile: payload.company_info_Mobile,

                                                        company_info_Address: payload.company_info_Address,
                                                        company_info_Country: payload.company_info_Country,
                                                        Type: payload.Type,
                                                        Receiver_info_First_name: payload.Receiver_info_First_name,
                                                        Receiver_info_Last_name: payload.Receiver_info_Last_name, Receiver_info_Title: payload.Receiver_info_Title,
                                                        Receiver_info_Email: payload.Receiver_info_Email,
                                                        Receiver_info_Mobile: payload.Receiver_info_Mobile,

                                                        Receiver_info_Address: payload.Receiver_info_Address,
                                                        Receiver_info_Country: payload.Receiver_info_Country,
                                                    })
                                                }}
                                            />
                                        </InputGroup>
                                        {!payload.company_info_Name && validsan == true ? (
                                            <div className="error-message">{ErrorMessage.company_info_Name}</div>
                                        ) : null}
                                    </div>
                                    <div className="input-form-wrapper dot">
                                        <label>Email address </label>
                                        <InputGroup className="doc-input-wrapper">
                                            <Form.Control
                                                className={

                                                    'doc-form-input test valid'}
                                                placeholder="Email"
                                                value={payload.company_info_Email}
                                                name='name'
                                                disabled
                                                type="email"
                                                onChange={(e: any) => {
                                                    setPayload({
                                                        Invoice_Name: payload.Invoice_Name,
                                                        Upload_img: payload.Upload_img,
                                                        company_info_Name: payload.company_info_Name,
                                                        company_info_Title: payload.company_info_Title,
                                                        company_info_Email: e.target.value,
                                                        company_info_Address: payload.company_info_Address,
                                                        company_info_Mobile: payload.company_info_Mobile,

                                                        company_info_Country: payload.company_info_Country,
                                                        Type: payload.Type,
                                                        Receiver_info_First_name: payload.Receiver_info_First_name,
                                                        Receiver_info_Last_name: payload.Receiver_info_Last_name,
                                                        Receiver_info_Title: payload.Receiver_info_Title,
                                                        Receiver_info_Email: payload.Receiver_info_Email,
                                                        Receiver_info_Mobile: payload.Receiver_info_Mobile,

                                                        Receiver_info_Address: payload.Receiver_info_Address,
                                                        Receiver_info_Country: payload.Receiver_info_Country,
                                                    })
                                                }}
                                            />
                                        </InputGroup>
                                        {!payload.company_info_Email && validsan == true ? (
                                            <div className="error-message">{ErrorMessage.company_info_Email}</div>
                                        ) : null}

                                    </div>
                                    <div className="input-form-wrapper dot">
                                        <label>Phone number</label>
                                        <InputGroup className="doc-input-wrapper">
                                            <Form.Control
                                                className={

                                                    'doc-form-input test valid'}
                                                placeholder="Phone number"
                                                value={payload.company_info_Mobile}
                                                name='name'
                                                disabled
                                                type="text"
                                                onChange={(e: any) => {
                                                    setPayload({
                                                        Invoice_Name: payload.Invoice_Name,
                                                        Upload_img: payload.Upload_img,
                                                        company_info_Name: payload.company_info_Name,
                                                        company_info_Title: payload.company_info_Title,
                                                        company_info_Email: payload.company_info_Email,
                                                        company_info_Mobile: e.target.value,
                                                        company_info_Address: payload.company_info_Address,
                                                        company_info_Country: payload.company_info_Country,
                                                        Type: payload.Type,
                                                        Receiver_info_First_name: payload.Receiver_info_First_name,
                                                        Receiver_info_Last_name: payload.Receiver_info_Last_name,
                                                        Receiver_info_Title: payload.Receiver_info_Title,
                                                        Receiver_info_Email: payload.Receiver_info_Email,
                                                        Receiver_info_Mobile: payload.Receiver_info_Mobile,
                                                        Receiver_info_Address: payload.Receiver_info_Address,
                                                        Receiver_info_Country: payload.Receiver_info_Country,
                                                    })
                                                }}
                                            />
                                        </InputGroup>
                                        {!payload.company_info_Mobile && validsan == true ? (
                                            <div className="error-message">{ErrorMessage.company_info_Mobile}</div>
                                        ) : null}
                                    </div>
                                    <div className="input-form-wrapper dot">
                                        <label>Address</label>
                                        <InputGroup className="doc-input-wrapper">
                                            <Form.Control
                                                className={

                                                    'doc-form-input test valid'}
                                                placeholder="Address"
                                                value={payload.company_info_Address}
                                                name='name'
                                                disabled
                                                type="text"
                                                onChange={(e: any) => {
                                                    setPayload({
                                                        Invoice_Name: payload.Invoice_Name,
                                                        Upload_img: payload.Upload_img,
                                                        company_info_Name: payload.company_info_Name,

                                                        company_info_Title: payload.company_info_Title,
                                                        company_info_Email: payload.company_info_Email,
                                                        company_info_Mobile: payload.company_info_Mobile,

                                                        company_info_Address: e.target.value,
                                                        company_info_Country: payload.company_info_Country,
                                                        Type: payload.Type,
                                                        Receiver_info_First_name: payload.Receiver_info_First_name,
                                                        Receiver_info_Last_name: payload.Receiver_info_Last_name,
                                                        Receiver_info_Title: payload.Receiver_info_Title,
                                                        Receiver_info_Email: payload.Receiver_info_Email,
                                                        Receiver_info_Mobile: payload.Receiver_info_Mobile,

                                                        Receiver_info_Address: payload.Receiver_info_Address,
                                                        Receiver_info_Country: payload.Receiver_info_Country,
                                                    })
                                                }}
                                            />
                                        </InputGroup>
                                        {!payload.company_info_Address && validsan == true ? (
                                            <div className="error-message">{ErrorMessage.company_info_Address}</div>
                                        ) : null}
                                    </div>
                                    <div className="input-form-wrapper">
                                        <label>Country</label>
                                        <CountryDropdown
                                            value={payload.company_info_Country}
                                            valueType="full"
                                            disabled
                                            onChange={(e: any, val) => {
                                                console.log(e, val.target, ' e.target.value e.target.value');
                                                setPayload({
                                                    Invoice_Name: payload.Invoice_Name,
                                                    Upload_img: payload.Upload_img,
                                                    company_info_Name: payload.company_info_Name,
                                                    company_info_Title: payload.company_info_Title,
                                                    company_info_Email: payload.company_info_Email,
                                                    company_info_Mobile: payload.company_info_Mobile,

                                                    company_info_Address: payload.company_info_Address,
                                                    company_info_Country: e,
                                                    Type: payload.Type,
                                                    Receiver_info_First_name: payload.Receiver_info_First_name,
                                                    Receiver_info_Last_name: payload.Receiver_info_Last_name,
                                                    Receiver_info_Title: payload.Receiver_info_Title,
                                                    Receiver_info_Email: payload.Receiver_info_Email,
                                                    Receiver_info_Mobile: payload.Receiver_info_Mobile,

                                                    Receiver_info_Address: payload.Receiver_info_Address,
                                                    Receiver_info_Country: payload.Receiver_info_Country,
                                                })
                                            }}

                                            name="country"
                                            classes={'doc-form-input test valid'
                                            }
                                        />
                                        {!payload.company_info_Country && validsan == true ? (
                                            <div className="error-message">{ErrorMessage.company_info_Country}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className={styles.two_contendata}>
                                    <Typography className={styles.Typography_companyinfo}>Receiver’s Information </Typography>
                                    <div className="input-form-wrapper dot">
                                        <label>Customers name</label>
                                        {ReceiverHide == true ?
                                            <div onClick={() => {
                                                setReceiverHide(false)
                                                setselectReceiver('')
                                                setCustomer_nameReceiver("")
                                                setPayload({
                                                    Invoice_Name: payload.Invoice_Name,
                                                    Upload_img: payload.Upload_img,
                                                    company_info_Name: payload.company_info_Name,
                                                    company_info_Title: payload.company_info_Title,
                                                    company_info_Email: payload.company_info_Email,
                                                    company_info_Mobile: payload.company_info_Mobile,

                                                    company_info_Address: payload.company_info_Address,
                                                    company_info_Country: payload.company_info_Country,
                                                    Type: payload.Type,
                                                    Receiver_info_First_name: '',
                                                    Receiver_info_Last_name: '',
                                                    Receiver_info_Title: '',
                                                    Receiver_info_Email: '',
                                                    Receiver_info_Mobile: '',

                                                    Receiver_info_Address: '',
                                                    Receiver_info_Country: '',
                                                })
                                            }}>
                                                <Form.Select

                                                    style={{ width: "100%" }}
                                                    onChange={(e: any) => {
                                                        setCustomer_nameReceiver(e.target.value)
                                                    }}
                                                    value={customer_nameReceiverr}
                                                    name="Conversion_Currency"
                                                    className={styles.select_box_kes}
                                                >
                                                    <option selected value="">Select Customers</option>
                                                    {ListdoupDoun?.map((arrayElement: any, index: number) => {
                                                        return (
                                                            <option value={arrayElement.id} key={index + 1}>{arrayElement.name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </div> :
                                            <Form.Select
                                                style={{ width: "100%" }}
                                                disabled={ReceiverHide}
                                                onChange={(e: any) => {
                                                    setCustomer_nameReceiver(e.target.value)
                                                    var ListFind: any = ListdoupDoun.find((data: any) => data.id == e.target.value)
                                                    console.log(ListFind, options, "ListFindListFind");
                                                    const findcurrencies: any = options.find(
                                                        (data: any) => data.value == ListFind?.contact_country
                                                    );
                                                    if (ListFind?.customer_type == "individual") {
                                                        setPayload({
                                                            Invoice_Name: payload.Invoice_Name,
                                                            Upload_img: payload.Upload_img,
                                                            company_info_Name: payload.company_info_Name,
                                                            company_info_Title: payload.company_info_Title,
                                                            company_info_Email: payload.company_info_Email,
                                                            company_info_Mobile: payload.company_info_Mobile,

                                                            company_info_Address: payload.company_info_Address,
                                                            company_info_Country: payload.company_info_Country,
                                                            Type: "individual",
                                                            Receiver_info_First_name: ListFind.contact_first_name,
                                                            Receiver_info_Last_name: ListFind.contact_last_name,
                                                            Receiver_info_Title: ListFind?.name,
                                                            Receiver_info_Email: ListFind?.contact_email,
                                                            Receiver_info_Mobile: ListFind?.contact_phone,

                                                            Receiver_info_Address: ListFind?.contact_line1,
                                                            Receiver_info_Country: findcurrencies?.label,
                                                        })
                                                    } else {
                                                        setPayload({
                                                            Invoice_Name: payload.Invoice_Name,
                                                            Upload_img: payload.Upload_img,
                                                            company_info_Name: payload.company_info_Name,
                                                            company_info_Title: payload.company_info_Title,
                                                            company_info_Email: payload.company_info_Email,
                                                            company_info_Mobile: payload.company_info_Mobile,

                                                            company_info_Address: payload.company_info_Address,
                                                            company_info_Country: payload.company_info_Country,
                                                            Type: "organization",
                                                            Receiver_info_First_name: ListFind?.name,
                                                            Receiver_info_Last_name: '',
                                                            Receiver_info_Title: ListFind?.name,
                                                            Receiver_info_Email: ListFind?.contact_email,
                                                            Receiver_info_Mobile: ListFind?.contact_phone,

                                                            Receiver_info_Address: ListFind?.contact_line1,
                                                            Receiver_info_Country: findcurrencies?.label,
                                                        })
                                                    }

                                                    setselectReceiver(ListFind)
                                                }}
                                                value={customer_nameReceiverr}
                                                name="Conversion_Currency"
                                                className={styles.select_box_kes}
                                            >
                                                <option selected value="">Select Customers</option>
                                                {ListdoupDoun?.map((arrayElement: any, index: number) => {
                                                    return (
                                                        <option value={arrayElement.id} key={index + 1}>{arrayElement.name}</option>
                                                    )
                                                })}
                                            </Form.Select>}
                                        {!customer_nameReceiverr && validsan == true && ReceiverHide == false ? (
                                            <div className="error-message">{ErrorMessage.company_info_Name}</div>
                                        ) : null}
                                    </div>
                                    {ReceiverHide == true ?
                                        <Button className='complete-profile__ADDBTNHHA'
                                        >
                                            + Add new Customers
                                        </Button> :
                                        <Button className='complete-profile__ADDBTNHHA'
                                            onClick={() => {
                                                setPayload({
                                                    Invoice_Name: payload.Invoice_Name,
                                                    Upload_img: payload.Upload_img,
                                                    company_info_Name: payload.company_info_Name,
                                                    company_info_Title: payload.company_info_Title,
                                                    company_info_Email: payload.company_info_Email,
                                                    company_info_Mobile: payload.company_info_Mobile,

                                                    company_info_Address: payload.company_info_Address,
                                                    company_info_Country: payload.company_info_Country,
                                                    Type: payload.Type,
                                                    Receiver_info_First_name: '',
                                                    Receiver_info_Last_name: '',
                                                    Receiver_info_Title: '',
                                                    Receiver_info_Email: '',
                                                    Receiver_info_Mobile: '',

                                                    Receiver_info_Address: '',
                                                    Receiver_info_Country: '',
                                                })
                                                setselectReceiver('')
                                                setCustomer_nameReceiver("")
                                                setReceiverHide(true)
                                            }}
                                        >
                                            + Add new Customers
                                        </Button>}
                                    <>
                                        <div className={styles.main_fast_and_last}>
                                            <div className={styles.fast_name_inpurt_div}>
                                                <div className="input-form-wrapper dot">
                                                    <label>{payload.Type == "individual" ? "First name" : "Organization name"}</label>
                                                    <InputGroup className="doc-input-wrapper">
                                                        <Form.Control
                                                            disabled={ReceiverHide == false ? true : false}
                                                            className={

                                                                'doc-form-input test valid'}
                                                            placeholder={payload.Type == "individual" ? "First name" : "Organization name"}
                                                            value={payload.Receiver_info_First_name}
                                                            name='name'
                                                            type="text"
                                                            onChange={(e: any) => {
                                                                setPayload({
                                                                    Invoice_Name: payload.Invoice_Name,
                                                                    Upload_img: payload.Upload_img,
                                                                    company_info_Name: payload.company_info_Name,
                                                                    company_info_Title: payload.company_info_Title,
                                                                    company_info_Email: payload.company_info_Email,
                                                                    company_info_Mobile: payload.company_info_Mobile,
                                                                    company_info_Address: payload.company_info_Address,
                                                                    company_info_Country: payload.company_info_Country,
                                                                    Type: payload.Type,
                                                                    Receiver_info_First_name: e.target.value,
                                                                    Receiver_info_Last_name: payload.Receiver_info_Last_name,
                                                                    Receiver_info_Title: payload.Receiver_info_Title,
                                                                    Receiver_info_Email: payload.Receiver_info_Email,
                                                                    Receiver_info_Mobile: payload.Receiver_info_Mobile,
                                                                    Receiver_info_Address: payload.Receiver_info_Address,
                                                                    Receiver_info_Country: payload.Receiver_info_Country,
                                                                })
                                                            }}
                                                        />
                                                    </InputGroup>
                                                    {!payload.Receiver_info_First_name && validsan == true && ReceiverHide == true ? (
                                                        <p className="error-message">
                                                            Please enter the{' '}
                                                            {payload.Type == 'individual'
                                                                ? 'first name'
                                                                : 'organization name'}
                                                        </p>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles.fast_name_inpurt_div}>
                                                {payload.Type == 'organization' ? (
                                                    ''
                                                ) : (
                                                    <div className="input-form-wrapper dot">
                                                        <label>Last name</label>
                                                        <InputGroup className="doc-input-wrapper">
                                                            <Form.Control
                                                                disabled={payload.Type == 'individual' && ReceiverHide == true ? false : true}
                                                                className={'doc-form-input test valid'}
                                                                placeholder="Last name"
                                                                value={payload.Receiver_info_Last_name}
                                                                name="name"
                                                                type="text"
                                                                onChange={(e: any) => {
                                                                    setPayload({
                                                                        Invoice_Name: payload.Invoice_Name,
                                                                        Upload_img: payload.Upload_img,
                                                                        company_info_Name: payload.company_info_Name,
                                                                        company_info_Title: payload.company_info_Title,
                                                                        company_info_Email: payload.company_info_Email,
                                                                        company_info_Mobile: payload.company_info_Mobile,

                                                                        company_info_Address: payload.company_info_Address,
                                                                        company_info_Country: payload.company_info_Country,
                                                                        Type: payload.Type,
                                                                        Receiver_info_First_name: payload.Receiver_info_First_name,
                                                                        Receiver_info_Last_name: e.target.value,
                                                                        Receiver_info_Title: payload.Receiver_info_Title,
                                                                        Receiver_info_Email: payload.Receiver_info_Email,
                                                                        Receiver_info_Mobile: payload.Receiver_info_Mobile,
                                                                        Receiver_info_Address: payload.Receiver_info_Address,
                                                                        Receiver_info_Country: payload.Receiver_info_Country,
                                                                    })
                                                                }}
                                                            />
                                                        </InputGroup>
                                                        {!payload.Receiver_info_Last_name && ReceiverHide == true &&
                                                            payload.Type !== 'organization' &&
                                                            validsan == true ? (
                                                            <p className="error-message">Please enter the last name</p>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                            className="maib_redio_button_contenar"
                                            value={payload.Type}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setPayload({
                                                    Invoice_Name: payload.Invoice_Name,
                                                    Upload_img: payload.Upload_img,
                                                    company_info_Name: payload.company_info_Name,
                                                    company_info_Title: payload.company_info_Title,
                                                    company_info_Email: payload.company_info_Email,
                                                    company_info_Mobile: payload.company_info_Mobile,

                                                    company_info_Address: payload.company_info_Address,
                                                    company_info_Country: payload.company_info_Country,
                                                    Type: (event.target as HTMLInputElement).value,
                                                    Receiver_info_First_name: payload.Receiver_info_First_name,
                                                    Receiver_info_Last_name: payload.Receiver_info_Last_name,
                                                    Receiver_info_Title: payload.Receiver_info_Title,
                                                    Receiver_info_Email: payload.Receiver_info_Email,
                                                    Receiver_info_Mobile: payload.Receiver_info_Mobile,
                                                    Receiver_info_Address: payload.Receiver_info_Address,
                                                    Receiver_info_Country: payload.Receiver_info_Country,
                                                })
                                            }}
                                        >
                                            <FormControlLabel
                                                className="main_redio_buttton"
                                                value="individual"
                                                control={
                                                    <Radio
                                                        disabled={ReceiverHide == false ? true : false}
                                                        // disabled={!!datadisplay ? true : false}
                                                        icon={<img src="/Checkbox.svg" />}
                                                        checkedIcon={<img src="/Checkbox_two.svg" />}
                                                    />
                                                }
                                                label="Individual"
                                            />
                                            <FormControlLabel
                                                className="main_redio_buttton"
                                                value="organization"
                                                control={
                                                    <Radio
                                                        disabled={ReceiverHide == false ? true : false}
                                                        // disabled={!!datadisplay ? true : false}
                                                        icon={<img src="/Checkbox.svg" />}
                                                        checkedIcon={<img src="/Checkbox_two.svg" />}
                                                    />
                                                }
                                                label="Organization"
                                            />
                                        </RadioGroup>
                                        <div className="input-form-wrapper dot">
                                            <label>Company name </label>
                                            <InputGroup className="doc-input-wrapper">
                                                <Form.Control
                                                    disabled={ReceiverHide == false ? true : false}
                                                    className={

                                                        'doc-form-input test valid'}
                                                    placeholder="Company name"
                                                    value={payload.Receiver_info_Title}
                                                    name='name'
                                                    type="text"
                                                    onChange={(e: any) => {
                                                        setPayload({
                                                            Invoice_Name: payload.Invoice_Name,
                                                            Upload_img: payload.Upload_img,
                                                            company_info_Name: payload.company_info_Name,
                                                            company_info_Title: payload.company_info_Title,
                                                            company_info_Email: payload.company_info_Email,
                                                            company_info_Mobile: payload.company_info_Mobile, company_info_Address: payload.company_info_Address,
                                                            company_info_Country: payload.company_info_Country,
                                                            Type: payload.Type,
                                                            Receiver_info_First_name: payload.Receiver_info_First_name,
                                                            Receiver_info_Last_name: payload.Receiver_info_Last_name,
                                                            Receiver_info_Title: e.target.value,
                                                            Receiver_info_Email: payload.Receiver_info_Email,
                                                            Receiver_info_Mobile: payload.Receiver_info_Mobile,
                                                            Receiver_info_Address: payload.Receiver_info_Address,
                                                            Receiver_info_Country: payload.Receiver_info_Country,
                                                        })
                                                    }}
                                                />
                                            </InputGroup>
                                            {!payload.Receiver_info_Title && validsan == true && ReceiverHide == true ? (
                                                <div className="error-message">{ErrorMessage.company_info_Name}</div>
                                            ) : null}
                                        </div>
                                        <div className="input-form-wrapper dot">
                                            <label>Email address</label>
                                            <InputGroup className="doc-input-wrapper">
                                                <Form.Control
                                                    disabled={ReceiverHide == false ? true : false}
                                                    className={

                                                        'doc-form-input test valid'}
                                                    placeholder="Email"
                                                    value={payload.Receiver_info_Email}
                                                    name='name'
                                                    type="email"
                                                    onChange={(e: any) => {
                                                        setPayload({
                                                            Invoice_Name: payload.Invoice_Name,
                                                            Upload_img: payload.Upload_img,
                                                            company_info_Name: payload.company_info_Name,
                                                            company_info_Title: payload.company_info_Title,
                                                            company_info_Email: payload.company_info_Email,
                                                            company_info_Mobile: payload.company_info_Mobile,

                                                            company_info_Address: payload.company_info_Address,
                                                            company_info_Country: payload.company_info_Country,
                                                            Type: payload.Type,
                                                            Receiver_info_First_name: payload.Receiver_info_First_name,
                                                            Receiver_info_Last_name: payload.Receiver_info_Last_name,
                                                            Receiver_info_Title: payload.Receiver_info_Title,
                                                            Receiver_info_Email: e.target.value,
                                                            Receiver_info_Mobile: payload.Receiver_info_Mobile,

                                                            Receiver_info_Address: payload.Receiver_info_Address,
                                                            Receiver_info_Country: payload.Receiver_info_Country,
                                                        })
                                                    }}
                                                />
                                            </InputGroup>
                                            {!payload.Receiver_info_Email && ReceiverHide == true && validsan == true ? (
                                                <div className="error-message">{ErrorMessage.company_info_Email}</div>
                                            ) : null}
                                        </div>
                                        <div className="input-form-wrapper dot">
                                            <label>Phone number</label>
                                            <InputGroup className="doc-input-wrapper">
                                                <Form.Control
                                                    disabled={ReceiverHide == false ? true : false}
                                                    className={

                                                        'doc-form-input test valid'}
                                                    placeholder="Phone number"
                                                    value={payload.Receiver_info_Mobile}
                                                    name='name'
                                                    type="number"
                                                    onChange={(e: any) => {
                                                        setPayload({
                                                            Invoice_Name: payload.Invoice_Name,
                                                            Upload_img: payload.Upload_img,
                                                            company_info_Name: payload.company_info_Name,
                                                            company_info_Title: payload.company_info_Title,
                                                            company_info_Email: payload.company_info_Email,
                                                            company_info_Mobile: payload.company_info_Mobile,

                                                            company_info_Address: payload.company_info_Address,
                                                            company_info_Country: payload.company_info_Country,
                                                            Type: payload.Type,
                                                            Receiver_info_First_name: payload.Receiver_info_First_name,
                                                            Receiver_info_Last_name: payload.Receiver_info_Last_name, Receiver_info_Title: payload.Receiver_info_Title,
                                                            Receiver_info_Email: payload.Receiver_info_Email,
                                                            Receiver_info_Mobile: e.target.value,

                                                            Receiver_info_Address: payload.Receiver_info_Address,
                                                            Receiver_info_Country: payload.Receiver_info_Country,
                                                        })
                                                    }}
                                                />
                                            </InputGroup>
                                            {!payload.Receiver_info_Mobile && ReceiverHide == true && validsan == true ? (
                                                <div className="error-message">{ErrorMessage.company_info_Mobile}</div>
                                            ) : null}
                                        </div>
                                        <div className="input-form-wrapper dot">
                                            <label>Address</label>
                                            <InputGroup className="doc-input-wrapper">
                                                <Form.Control
                                                    disabled={ReceiverHide == false ? true : false}
                                                    className={

                                                        'doc-form-input test valid'}
                                                    placeholder="Address"
                                                    value={payload.Receiver_info_Address}
                                                    name='name'
                                                    type="text"
                                                    onChange={(e: any) => {
                                                        setPayload({
                                                            Invoice_Name: payload.Invoice_Name,
                                                            Upload_img: payload.Upload_img,
                                                            company_info_Name: payload.company_info_Name,
                                                            company_info_Title: payload.company_info_Title,
                                                            company_info_Email: payload.company_info_Email,
                                                            company_info_Mobile: payload.company_info_Mobile,

                                                            company_info_Address: payload.company_info_Address,
                                                            company_info_Country: payload.company_info_Country,
                                                            Type: payload.Type,
                                                            Receiver_info_First_name: payload.Receiver_info_First_name,
                                                            Receiver_info_Last_name: payload.Receiver_info_Last_name, Receiver_info_Title: payload.Receiver_info_Title,
                                                            Receiver_info_Email: payload.Receiver_info_Email,
                                                            Receiver_info_Mobile: payload.Receiver_info_Mobile,

                                                            Receiver_info_Address: e.target.value,
                                                            Receiver_info_Country: payload.Receiver_info_Country,
                                                        })
                                                    }}
                                                />
                                            </InputGroup>
                                            {!payload.Receiver_info_Address && ReceiverHide == true && validsan == true ? (
                                                <div className="error-message">{ErrorMessage.company_info_Address}</div>
                                            ) : null}
                                        </div>
                                        <div className="input-form-wrapper">
                                            <label>Country</label>
                                            <CountryDropdown
                                                disabled={ReceiverHide == false ? true : false}
                                                value={payload.Receiver_info_Country}
                                                valueType="full"
                                                onChange={(e: any, val) => {
                                                    console.log(e, ' e.target.value e.target.value');
                                                    setPayload({
                                                        Invoice_Name: payload.Invoice_Name,
                                                        Upload_img: payload.Upload_img,
                                                        company_info_Name: payload.company_info_Name,
                                                        company_info_Title: payload.company_info_Title,
                                                        company_info_Email: payload.company_info_Email,
                                                        company_info_Mobile: payload.company_info_Mobile,

                                                        company_info_Address: payload.company_info_Address,
                                                        company_info_Country: payload.company_info_Country,
                                                        Type: payload.Type,
                                                        Receiver_info_First_name: payload.Receiver_info_First_name,
                                                        Receiver_info_Last_name: payload.Receiver_info_Last_name, Receiver_info_Title: payload.Receiver_info_Title,
                                                        Receiver_info_Email: payload.Receiver_info_Email,
                                                        Receiver_info_Mobile: payload.Receiver_info_Mobile,

                                                        Receiver_info_Address: payload.Receiver_info_Address,
                                                        Receiver_info_Country: e,
                                                    })
                                                }}

                                                name="country"
                                                classes={'doc-form-input test valid'
                                                }
                                            />
                                            {!payload.Receiver_info_Country && ReceiverHide == true && validsan == true ? (
                                                <div className="error-message">{ErrorMessage.company_info_Country}</div>
                                            ) : null}
                                        </div>
                                    </>
                                </div>
                            </div>
                            <div className={styles.div_buttomborder}>

                            </div>
                            <Typography className={styles.Typography_companyinfo} style={{ paddingTop: "15px" }}>Amount</Typography>

                            {itemsbill.map((x, i: any) => {
                                console.log(x.description_data, "x.description_data");

                                return (
                                    <>
                                        <div className={styles.remove_button}>
                                            {i > 0 ? <Button style={{ paddingBottom: "0px" }} className='complete-profile__removebtn' onClick={() => { handleRemoveClick(i) }}>Remove</Button> : ''}
                                        </div>
                                        <div className={styles.contenar_typo_left}>

                                            <div className={styles.fast_contean} style={{ paddingTop: "0px" }}>
                                                <div className={styles.two_input_dataa}>
                                                    <div id={styles.in_haf_cambain} style={{ margin: "10px 0px 20px 0px" }} className="input-form-wrapper dot">
                                                        <label>Item</label>
                                                        <InputGroup className="doc-input-wrapper">
                                                            <Form.Control
                                                                className={

                                                                    'doc-form-input test valid'}
                                                                placeholder="Item"
                                                                value={x.Item}
                                                                name='name'
                                                                type="text"
                                                                onChange={(e: any) => {
                                                                    // const newdata = itemsbill;
                                                                    // newdata[i].Item = e.target.value
                                                                    // setItemsbill(newdata);
                                                                    const arrrr: any = []
                                                                    for (let index = 0; index < itemsbill.length; index++) {
                                                                        const element = itemsbill[index];
                                                                        // const newdata = directors;

                                                                        if (i == index) {
                                                                            element.Item = e.target.value

                                                                            arrrr.push(element)
                                                                        } else {
                                                                            arrrr.push(element)
                                                                        }
                                                                    }
                                                                    const apiarr: any = []
                                                                    for (let index = 0; index < bill_items.length; index++) {
                                                                        const element = bill_items[index];
                                                                        // const newdata = directors;

                                                                        if (i == index) {
                                                                            element.title = e.target.value

                                                                            apiarr.push(element)
                                                                        } else {
                                                                            apiarr.push(element)
                                                                        }
                                                                    }
                                                                    setBill_items(apiarr)
                                                                    setItemsbill(arrrr)
                                                                    // setInfo({
                                                                    //     Invoice: e.target.value,
                                                                    //     Date_issued: info.Date_issued,
                                                                    //     Due_date: info.Due_date
                                                                    // })
                                                                }}
                                                            />
                                                        </InputGroup>
                                                        {!x.Item && validsan == true ? (
                                                            <div className="error-message">{ErrorMessage.Item}</div>
                                                        ) : null}
                                                    </div>
                                                    <div id={styles.in_haf_cambain} style={{ margin: "10px 0px 20px 0px" }} className="input-form-wrapper dot">
                                                        <label>Quantity</label>
                                                        <InputGroup className="doc-input-wrapper">
                                                            <Form.Control
                                                                className={

                                                                    'doc-form-input test valid'}
                                                                placeholder="Quantity"
                                                                value={x.Quantity}
                                                                name='name'
                                                                type="number"
                                                                onChange={(e: any) => {
                                                                    // const newdata = itemsbill;
                                                                    // newdata[i].Quantity = e.target.value
                                                                    // setItemsbill(newdata);
                                                                    const arrrr: any = []
                                                                    for (let index = 0; index < itemsbill.length; index++) {
                                                                        const element = itemsbill[index];
                                                                        // const newdata = directors;

                                                                        if (i == index) {
                                                                            element.Quantity = e.target.value.replaceAll(',', '')
                                                                            element.total = (+e.target.value.replaceAll(',', '') * +x.Price)
                                                                            // amount_data += +(+e.target.value.replaceAll(',', '') * +example.quantity).toString()
                                                                            arrrr.push(element)
                                                                        } else {
                                                                            arrrr.push(element)
                                                                        }
                                                                    }
                                                                    const APIarr: any = []
                                                                    for (let index = 0; index < bill_items.length; index++) {
                                                                        const element = bill_items[index];
                                                                        // const newdata = directors;

                                                                        if (i == index) {
                                                                            element.quantity = e.target.value.replaceAll(',', '')
                                                                            element.total = (+e.target.value.replaceAll(',', '') * +element.price)
                                                                            // amount_data += +(+e.target.value.replaceAll(',', '') * +example.quantity).toString()
                                                                            APIarr.push(element)
                                                                        } else {
                                                                            APIarr.push(element)
                                                                        }
                                                                    }
                                                                    setBill_items(APIarr)
                                                                    setItemsbill(arrrr)
                                                                }}
                                                            />
                                                        </InputGroup>
                                                        {!x.Quantity && validsan == true ? (
                                                            <div className="error-message">{ErrorMessage.Quantity}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.two_contendata} style={{ paddingTop: "0px" }}>
                                                <div className={styles.two_input_dataa}>
                                                    <div id={styles.in_haf_cambain} style={{ margin: "10px 0px 20px 0px" }} className="input-form-wrapper dot">
                                                        <label>Price</label>
                                                        <InputGroup className="doc-input-wrapper">
                                                            <Form.Control
                                                                className={

                                                                    'doc-form-input test valid'}
                                                                placeholder="Price"
                                                                value={x.Price}
                                                                name='name'
                                                                type="number"
                                                                onChange={(e: any) => {
                                                                    // const newdata = itemsbill;
                                                                    // newdata[i].Price = e.target.value
                                                                    // setItemsbill(newdata);
                                                                    const arrrr: any = []
                                                                    for (let index = 0; index < itemsbill.length; index++) {
                                                                        const element = itemsbill[index];
                                                                        // const newdata = directors;

                                                                        if (i == index) {
                                                                            element.Price = e.target.value.replaceAll(',', '')
                                                                            element.total = (+e.target.value.replaceAll(',', '') * +x.Quantity)

                                                                            // amount_data += +(+e.target.value.replaceAll(',', '') * +example.quantity).toString()
                                                                            arrrr.push(element)
                                                                        } else {
                                                                            arrrr.push(element)
                                                                        }
                                                                    }
                                                                    const APIarr: any = []
                                                                    for (let index = 0; index < bill_items.length; index++) {
                                                                        const element = bill_items[index];
                                                                        // const newdata = directors;

                                                                        if (i == index) {
                                                                            element.price = e.target.value.replaceAll(',', '')
                                                                            element.total = (+e.target.value.replaceAll(',', '') * +element.quantity)

                                                                            // amount_data += +(+e.target.value.replaceAll(',', '') * +example.quantity).toString()
                                                                            APIarr.push(element)
                                                                        } else {
                                                                            APIarr.push(element)
                                                                        }
                                                                    }
                                                                    setBill_items(APIarr)
                                                                    setItemsbill(arrrr)
                                                                    // setInfo({
                                                                    //     Invoice: e.target.value,
                                                                    //     Date_issued: info.Date_issued,
                                                                    //     Due_date: info.Due_date
                                                                    // })
                                                                }}
                                                            />
                                                        </InputGroup>
                                                        {!x.Price && validsan == true ? (
                                                            <div className="error-message">{ErrorMessage.Price}</div>
                                                        ) : null}
                                                    </div>
                                                    <div id={styles.in_haf_cambain} style={{ margin: "10px 0px 20px 0px", paddingTop: "20px" }} className="input-form-wrapper">
                                                        <label></label>
                                                        <InputGroup className="doc-input-wrapper">
                                                            <Form.Control
                                                                disabled
                                                                className={

                                                                    'doc-form-input test valid'}
                                                                placeholder="$0.00"
                                                                value={x.total}
                                                                name='name'
                                                                type="text"
                                                                onChange={(e: any) => {
                                                                    // const newdata = itemsbill;
                                                                    // newdata[i].Quantity = e.target.value
                                                                    // setItemsbill(newdata);
                                                                }}
                                                            />
                                                        </InputGroup>
                                                        {/* {!x.Quantity ? (
                                                            <div className="error-message">{ErrorMessage.Quantity}</div>
                                                        ) : null} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.description_contean}>

                                            {x.description_data == true ?
                                                <div style={{ width: "100%" }} className="input-form-wrapper">
                                                    <label>Description </label>
                                                    <InputGroup className="doc-input-wrapper">
                                                        <Form.Control
                                                            className={

                                                                'doc-form-input test valid'}
                                                            placeholder="Item"
                                                            value={x.description}
                                                            name='name'
                                                            type="text"
                                                            onChange={(e: any) => {
                                                                const arrrr: any = []
                                                                for (let index = 0; index < itemsbill.length; index++) {
                                                                    const element = itemsbill[index];
                                                                    // const newdata = directors;

                                                                    if (i == index) {
                                                                        element.description = e.target.value
                                                                        arrrr.push(element)
                                                                    } else {
                                                                        arrrr.push(element)
                                                                    }
                                                                }
                                                                const APIarr: any = []
                                                                for (let index = 0; index < bill_items.length; index++) {
                                                                    const element = bill_items[index];
                                                                    // const newdata = directors;

                                                                    if (i == index) {
                                                                        element.description = e.target.value
                                                                        // amount_data += +(+e.target.value.replaceAll(',', '') * +example.quantity).toString()
                                                                        APIarr.push(element)
                                                                    } else {
                                                                        APIarr.push(element)
                                                                    }
                                                                }
                                                                setBill_items(APIarr)
                                                                setItemsbill(arrrr)


                                                            }}
                                                        />
                                                    </InputGroup> </div> : ""}
                                            <Button className={styles.button_description} onClick={() => {
                                                // const newdata = itemsbill;
                                                // newdata[i].description_data = true
                                                // setItemsbill(newdata);
                                                const arrrr: any = []
                                                for (let index = 0; index < itemsbill.length; index++) {
                                                    const element = itemsbill[index];
                                                    // const newdata = directors;

                                                    if (i == index) {
                                                        element.description_data = true
                                                        arrrr.push(element)
                                                    } else {
                                                        arrrr.push(element)
                                                    }
                                                }
                                                setItemsbill(arrrr)
                                            }}>
                                                + Add description (optional)
                                            </Button>

                                        </div>
                                    </>

                                )
                            })}
                            <Button className={styles.button_lineitem} onClick={() => { handleAddClick() }}>+ Add line item</Button>
                            <div className={styles.div_buttomborder}>

                            </div>
                            <div>
                                <Grid container>
                                    <Grid item md={6}>
                                        <Typography className={styles.textarea_Typography}>Payment Details</Typography>
                                        <textarea onChange={(e: any) => {

                                            setFooterdata({
                                                totel: footerdata.totel,
                                                textrea: e.target.value,
                                                Tax: footerdata.Tax,
                                                Discount_type: footerdata.Discount_type,
                                                Discount: footerdata.Discount,
                                                pagetotel: footerdata.pagetotel,
                                                Discount_value: footerdata.Discount_value,
                                                includes_tax: footerdata.includes_tax,
                                                tex_totel: footerdata.tex_totel,
                                                includes_Discount: footerdata.includes_Discount
                                            })
                                        }} id="w3review" className={styles.textarea} name="w3review"
                                            rows={6}
                                            placeholder="Add a note or any terms & conditions (i.e. payment methods, late fees, etc) "
                                        // cols={58}
                                        >

                                        </textarea>
                                        {props.data == "logout" ?
                                            <>
                                                <Typography className={styles.Typography_styles}>
                                                    You can also use Niobi to collect payment directly from your customers by adding a payment link to this invoice. To add a payment link and setup your account  <a href='https://niobi.co/request-a-demo/' target='_blank'>click here</a>
                                                </Typography>
                                                <div className={styles.main_addbutton}>
                                                    <Button className={styles.PaymentLink_button} href='https://share-eu1.hsforms.com/1lJP3cMs0RX22gCrvdliOMwfuo5v' target='_blank'>
                                                        <a className={styles.a_yagesfffff} style={{ color: "#fff", textDecoration: "none" }} target='_blank' href='https://share-eu1.hsforms.com/1lJP3cMs0RX22gCrvdliOMwfuo5v'>Add Payment Link</a>
                                                    </Button>
                                                </div>
                                            </> : ""}
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <div className={styles.contenar_boad_data}>
                                            <div className={styles.div_totel_amout}>
                                                <Typography className={styles.textarea_Typography_two}>Subtotal</Typography>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Typography className={styles.textarea_Typography_minhithe}>{info.Currency}</Typography>
                                                    <Typography className={styles.textarea_Typography_two}>{footerdata.totel}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.conatebar_chackbox}>
                                            <div style={{ width: "70%" }} id={styles.div_checkboxx} className="amont_text_p_chackbox">
                                                <ThemeProvider theme={theme}>
                                                    <Checkbox
                                                        icon={<img src="/Checkbox.svg" />}
                                                        checkedIcon={<img src="/Checkbox_two.svg" />}
                                                        color="primary"
                                                        value={footerdata.includes_tax}
                                                        onClick={() => {
                                                            if (footerdata.includes_tax == true) {
                                                                setFooterdata({
                                                                    totel: footerdata.totel,
                                                                    textrea: footerdata.textrea,
                                                                    Tax: footerdata.Tax,
                                                                    Discount: footerdata.Discount,
                                                                    pagetotel: footerdata.pagetotel,
                                                                    includes_tax: false,
                                                                    Discount_value: footerdata.Discount_value,
                                                                    Discount_type: footerdata.Discount_type,
                                                                    tex_totel: footerdata.tex_totel,
                                                                    includes_Discount: footerdata.includes_Discount
                                                                })
                                                            } else {
                                                                setFooterdata({
                                                                    totel: footerdata.totel,
                                                                    textrea: footerdata.textrea,
                                                                    Tax: footerdata.Tax,
                                                                    Discount: footerdata.Discount,
                                                                    pagetotel: footerdata.pagetotel,
                                                                    includes_tax: true,
                                                                    Discount_value: footerdata.Discount_value,
                                                                    Discount_type: footerdata.Discount_type,
                                                                    tex_totel: footerdata.tex_totel,
                                                                    includes_Discount: footerdata.includes_Discount
                                                                })

                                                            }
                                                        }}
                                                        inputProps={{
                                                            'aria-label': 'select all desserts',
                                                        }}
                                                    />
                                                </ThemeProvider>
                                                <p>
                                                    This includes tax
                                                </p>
                                            </div>
                                        </div>
                                        {footerdata.includes_tax == true ?
                                            <div className={styles.conatebar_chackbox}>
                                                <div id={styles.vat_input} className="input-form-wrapper">
                                                    <label>VAT %</label>
                                                    <Form.Select name="Country"

                                                        value={vatType || footerdata.Tax}
                                                        onChange={(e) => {
                                                            console.log(((Number(footerdata.totel) - Number(footerdata.Discount_value)) / (1 + Number(footerdata.Tax) / 100)), "sdadsaDddddd");
                                                            setVatType(e.target.value == "Exempt" ? "Exempt" : "")

                                                            setFooterdata({
                                                                totel: footerdata.totel,
                                                                textrea: footerdata.textrea,
                                                                Tax: e.target.value == "Exempt" ? 0 : Number(e.target.value),
                                                                Discount: footerdata.Discount,
                                                                Discount_value: footerdata.Discount_value,
                                                                pagetotel: footerdata.pagetotel,
                                                                // (((Number(footerdata.totel) - Number(footerdata.Discount_value))-((Number(footerdata.totel) - Number(footerdata.Discount_value)) / (1 + e.target.value=="Exempt"?0:Number(e.target.value) / 100))) + (Number(footerdata.totel) - footerdata.Discount_value)),
                                                                includes_tax: footerdata.includes_tax,
                                                                Discount_type: footerdata.Discount_type,
                                                                includes_Discount: footerdata.includes_Discount,
                                                                tex_totel:footerdata.tex_totel
                                                            })
                                                        }}
                                                        // style={{ height: "23px" }}
                                                        className={'doc-form-input'}
                                                    >
                                                        <option selected value="">Select VAT %</option>
                                                        {vatall?.map((arrayElement: any, index: number) => {
                                                            return (
                                                                <option value={arrayElement.vat} key={index + 1}>{arrayElement.vat}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                    {/* {vat_data == '' && display_datamode == true ? <p className="err_text_ajjsjshhs">Please select the country where the business is located.</p> : ""} */}
                                                </div>
                                            </div> : ""}
                                        <div className={styles.contenar_boad_data}>
                                            <div className={styles.div_totel_amout}>
                                                <Typography className={styles.textarea_Typography_two}>Tax</Typography>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Typography className={styles.textarea_Typography_minhithe}>{info.Currency}</Typography>
                                                    <Typography className={styles.textarea_Typography_two}>{Number(footerdata.tex_totel)
                                                        // ((Number(footerdata.totel) - Number(footerdata.Discount_value))/((1+Number(footerdata.Tax))/100))
                                                        // ((((Number(footerdata.totel) - Number(footerdata.Discount_value)) / (1+Number(footerdata.Tax))) / 100))
                                                    }</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.conatebar_chackbox}>
                                            <div style={{ width: "70%" }} id={styles.div_checkboxx} className="amont_text_p_chackbox">
                                                <ThemeProvider theme={theme}>
                                                    <Checkbox
                                                        icon={<img src="/Checkbox.svg" />}
                                                        checkedIcon={<img src="/Checkbox_two.svg" />}
                                                        color="primary"
                                                        value={footerdata.includes_Discount}
                                                        onClick={() => {
                                                            if (footerdata.includes_Discount == true) {
                                                                setFooterdata({
                                                                    totel: footerdata.totel,
                                                                    textrea: footerdata.textrea,
                                                                    Tax: footerdata.Tax,
                                                                    Discount: footerdata.Discount,
                                                                    pagetotel: footerdata.pagetotel,
                                                                    Discount_value: footerdata.Discount_value,
                                                                    Discount_type: footerdata.Discount_type,
                                                                    includes_tax: footerdata.includes_tax,
                                                                    includes_Discount: false,
                                                                    tex_totel: footerdata.tex_totel
                                                                })
                                                            } else {
                                                                setFooterdata({
                                                                    totel: footerdata.totel,
                                                                    textrea: footerdata.textrea,
                                                                    Tax: footerdata.Tax,
                                                                    Discount_type: footerdata.Discount_type,
                                                                    Discount: footerdata.Discount,
                                                                    Discount_value: footerdata.Discount_value,
                                                                    pagetotel: footerdata.pagetotel,
                                                                    includes_tax: footerdata.includes_tax,
                                                                    includes_Discount: true,
                                                                    tex_totel: footerdata.tex_totel
                                                                })

                                                            }
                                                        }}
                                                        inputProps={{
                                                            'aria-label': 'select all desserts',
                                                        }}
                                                    />
                                                </ThemeProvider>
                                                <p>
                                                    This includes discount
                                                </p>
                                            </div>
                                        </div>
                                        {footerdata?.includes_Discount == true ?
                                            <div className={styles.conatebar_chackbox}>
                                                <div id={styles.disacaunt_input} className="input-form-wrapper">
                                                    <div style={{ width: "48.5%" }} id={styles.vat_input} className="input-form-wrapper">
                                                        <label>Discount type</label>
                                                        <Form.Select

                                                            onChange={(e: any) => {
                                                                if (e.target.value == "Normal") {
                                                                    setFooterdata({
                                                                        totel: footerdata.totel,
                                                                        textrea: footerdata.textrea,
                                                                        Tax: footerdata.Tax,
                                                                        Discount_type: e.target.value,
                                                                        Discount: footerdata.Discount,
                                                                        Discount_value: footerdata.Discount_value,
                                                                        pagetotel: ((Number(footerdata.totel) - Number(footerdata.Discount_value)) + ((Number(footerdata.totel) - Number(footerdata.Discount_value)) - ((Number(footerdata.totel) - Number(footerdata.Discount_value)) / (1 + Number(footerdata.Tax) / 100)))),

                                                                        // pagetotel: (((Number(footerdata.totel) - Number(footerdata.Discount_value)) / (1 + Number(footerdata.Tax) / 100)) + (Number(footerdata.totel) - Number(footerdata.Discount_value))),
                                                                        includes_tax: footerdata.includes_tax,
                                                                        includes_Discount: footerdata.includes_Discount,
                                                                        tex_totel: footerdata.tex_totel
                                                                    })
                                                                } else if (e.target.value == "Percentage") {
                                                                    setFooterdata({
                                                                        totel: footerdata.totel,
                                                                        textrea: footerdata.textrea,
                                                                        Tax: footerdata.Tax,
                                                                        Discount_type: e.target.value,
                                                                        Discount_value: footerdata.Discount_value,
                                                                        Discount: footerdata.Discount,
                                                                        pagetotel: ((Number(footerdata.totel) - ((Number(footerdata.totel) * Number(footerdata.Discount_value)) / 100)) + ((Number(footerdata.totel) - ((Number(footerdata.totel) * Number(footerdata.Discount_value)) / 100)) - ((Number(footerdata.totel) - ((Number(footerdata.totel) * Number(footerdata.Discount_value)) / 100)) / (1 + Number(footerdata.Tax) / 100)))),
                                                                        includes_tax: footerdata.includes_tax,
                                                                        includes_Discount: footerdata.includes_Discount,
                                                                        tex_totel: footerdata.tex_totel
                                                                    })
                                                                }


                                                            }}
                                                            value={footerdata.Discount_type}
                                                            name="Conversion_Currency"
                                                        // className={styles.select_box_kes}
                                                        >
                                                            {disacaunt_type?.map((arrayElement: any, index: number) => {
                                                                return (
                                                                    <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </div>
                                                    <div style={{ width: "48.5%" }} id={styles.vat_input} className="input-form-wrapper">
                                                        <label>Discount value {footerdata.Discount_type == 'Normal' ? "KES" : "%"}</label>
                                                        <InputGroup className="doc-input-wrapper">

                                                            <Form.Control
                                                                className={
                                                                    'doc-form-input test valid'}
                                                                placeholder="Discount"
                                                                value={footerdata.Discount}
                                                                name='name'
                                                                type="text"
                                                                onChange={(e) => {
                                                                    console.log("pagetotel", (Number(footerdata.totel) + ((Number(footerdata.totel) - ((Number(footerdata.totel) * Number(e.target.value)) / 100)) - ((Number(footerdata.totel) - ((Number(footerdata.totel) * Number(e.target.value)) / 100)) / (1 + Number(footerdata.Tax) / 100)))), ((Number(footerdata.totel) * Number(e.target.value)) / 100));

                                                                    setFooterdata({
                                                                        totel: footerdata.totel,
                                                                        textrea: footerdata.textrea,
                                                                        Tax: footerdata.Tax,
                                                                        Discount_type: footerdata.Discount_type,
                                                                        Discount_value: footerdata.Discount_type == 'Normal' ? Number(e.target.value) : ((Number(footerdata.totel) * Number(e.target.value)) / 100),
                                                                        Discount: footerdata.Discount_type == 'Normal' ? Number(e.target.value) : Number(e.target.value),
                                                                        pagetotel: footerdata.Discount_type == 'Normal' ? ((Number(footerdata.totel) - Number(e.target.value)) + ((Number(footerdata.totel) - Number(e.target.value)) - ((Number(footerdata.totel) - Number(e.target.value)) / (1 + Number(footerdata.Tax) / 100)))) :
                                                                            ((Number(footerdata.totel) - ((Number(footerdata.totel) * Number(e.target.value)) / 100)) + ((Number(footerdata.totel) - ((Number(footerdata.totel) * Number(e.target.value)) / 100)) - ((Number(footerdata.totel) - ((Number(footerdata.totel) * Number(e.target.value)) / 100)) / (1 + Number(footerdata.Tax) / 100)))),                                                                       //  : ((((Number(footerdata.totel) - ((Number(footerdata.totel) * Number(e.target.value)) / 100))) / (1 + Number(footerdata.Tax) / 100)) + (Number(footerdata.totel) - ((Number(footerdata.totel) * Number(e.target.value)) / 100))),
                                                                        includes_tax: footerdata.includes_tax,
                                                                        tex_totel: footerdata.tex_totel,
                                                                        includes_Discount: footerdata.includes_Discount
                                                                    })
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                </div>
                                            </div> : ""}
                                        <div className={styles.contenar_boad_data}>
                                            <div className={styles.div_totel_amout}>
                                                <Typography className={styles.textarea_Typography_two}>Discount</Typography>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Typography className={styles.textarea_Typography_minhithe}>{info.Currency}</Typography>
                                                    <Typography className={styles.textarea_Typography_two}>{footerdata.Discount_type == 'Percentage' ? (((Number(footerdata.totel) * Number(footerdata.Discount)) / 100)) : footerdata.Discount}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.contenar_boad_data}>
                                            <div className={styles.div_totel_amout}>
                                                <Typography className={styles.textarea_Typography_two}>Total</Typography>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Typography className={styles.textarea_Typography_minhithe}>{info.Currency}</Typography>
                                                    <Typography className={styles.textarea_Typography_two}>{footerdata.pagetotel.toLocaleString(
                              'en',
                              { minimumFractionDigits: 2 }
                            )}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        {/* <Grid item md={4} display={'flex'} justifyContent={'end'} alignItems={'center'} style={{padding:"44px 0px 0px 0px"}}>
                       
                        </Grid> */}
                        <Grid item md={4} xs={12}>
                            {validesannodata == false ?
                                <Preview_nodata /> : <Preview_fils idPage={idPage} Login={props.data} selectReceiver={selectReceiver} ReceiverHide={ReceiverHide} invoiceImage={invoiceImage} onCancel={onCancel} validesanpayload={validesanpayload} vatType={vatType} info={info} data={payload} bill_items={bill_items} footerdata={footerdata} />}
                        </Grid>
                    </Grid>
                </Grid >
            </ThemeProvider >
        </>
    )
}
export default Create_your_invoiceIn;

const UploadLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.21875 2.79023C2.90766 2.79023 2.65547 3.04243 2.65547 3.35351V12.366C2.65547 12.5076 2.7077 12.637 2.79395 12.736L9.79993 6.0484C10.0726 5.7881 10.4994 5.78053 10.7811 6.03098L12.7945 7.82066V3.35351C12.7945 3.04243 12.5423 2.79023 12.2313 2.79023H3.21875ZM12.7945 9.74954C12.7673 9.73128 12.7409 9.71097 12.7157 9.68855L10.3175 7.55677L4.68912 12.9293H12.2313C12.5423 12.9293 12.7945 12.6771 12.7945 12.366V9.74954ZM1.20703 3.35351C1.20703 2.24247 2.10771 1.3418 3.21875 1.3418H12.2313C13.3423 1.3418 14.243 2.24247 14.243 3.35351V12.366C14.243 13.4771 13.3423 14.3777 12.2313 14.3777H3.21875C2.10771 14.3777 1.20703 13.4771 1.20703 12.366V3.35351ZM5.47188 5.36524C5.33855 5.36524 5.23047 5.47332 5.23047 5.60664C5.23047 5.73997 5.33855 5.84805 5.47188 5.84805C5.6052 5.84805 5.71328 5.73997 5.71328 5.60664C5.71328 5.47332 5.6052 5.36524 5.47188 5.36524ZM3.78203 5.60664C3.78203 4.67337 4.5386 3.9168 5.47188 3.9168C6.40514 3.9168 7.16172 4.67337 7.16172 5.60664C7.16172 6.53991 6.40514 7.29649 5.47188 7.29649C4.5386 7.29649 3.78203 6.53991 3.78203 5.60664Z" fill="#025041" />
    </svg>
)

const Upload_svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.27578 1.33691C8.46785 1.33691 8.65207 1.41322 8.78788 1.54903L12.0067 4.76778C12.2895 5.05061 12.2895 5.50916 12.0067 5.79198C11.7238 6.07481 11.2653 6.07481 10.9824 5.79198L9 3.80955V9.78613C9 10.1861 8.67575 10.5104 8.27578 10.5104C7.87581 10.5104 7.55156 10.1861 7.55156 9.78613V3.80955L5.56913 5.79198C5.2863 6.07481 4.82776 6.07481 4.54493 5.79198C4.2621 5.50916 4.2621 5.05061 4.54493 4.76778L7.76368 1.54903C7.8995 1.41322 8.08371 1.33691 8.27578 1.33691ZM2.48203 9.06191C2.882 9.06191 3.20625 9.38616 3.20625 9.78613V12.3612C3.20625 12.5105 3.2656 12.6537 3.37123 12.7594C3.47687 12.865 3.62014 12.9244 3.76953 12.9244H12.7821C12.9314 12.9244 13.0746 12.865 13.1803 12.7594C13.2859 12.6537 13.3453 12.5105 13.3453 12.3612V9.78613C13.3453 9.38616 13.6696 9.06191 14.0695 9.06191C14.4695 9.06191 14.7937 9.38616 14.7937 9.78613V12.3612C14.7937 12.8947 14.5818 13.4064 14.2045 13.7836C13.8273 14.1609 13.3156 14.3729 12.7821 14.3729H3.76953C3.23599 14.3729 2.7243 14.1609 2.34703 13.7836C1.96976 13.4064 1.75781 12.8947 1.75781 12.3612V9.78613C1.75781 9.38616 2.08206 9.06191 2.48203 9.06191Z" fill="#1F1F1F" fill-opacity="0.8" />
    </svg>

)