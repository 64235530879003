import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import moment from 'moment-timezone';
import "./TransactionDetails.scss";
import { getUser } from "../../../../../../_helpers";
import Modal from 'react-bootstrap/Modal';
import { balanceActions } from "../../../../../../_actions/balance.actions";
import { useDispatch, useSelector } from "react-redux";
import Menu from '@mui/material/Menu';
import { selectAllBalanceLIstData } from "../../../../../../_reducers/balance.reducer";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { WhatsappShareButton, EmailShareButton } from 'react-share';
import { Box, Typography,IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
    onClose: any;
    show: boolean;
    data: any;
}

export const TransactionDetails = (props: Props) => {
    const [option, setOption] = useState<any>({
        id: ''
    });
    const dispatch = useDispatch();
    const [balance, setBalance] = useState<any>();
    const [tagname, settagname] = useState<any>('');
    const [showModal, setShow] = useState(props.show);
    const [dataDisplay, setDataDisplay] = useState(false);
    const [Tagclass, setTagclass] = useState('');
    const [balanceRender, setBalanceRender] = useState(false);
    const [datadesplay, setDatadesplay] = useState(true)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        dispatch(balanceActions.getPdf(props.data.reference) as any);
        setDatadesplay(true)
        setTimeout(() => {
            setBalanceRender(true)
        }, 3000)
    };
    const handleClose_twoo = () => {
        setAnchorEl(null);
    };
    const user = getUser()?.data ?? null;
    const balancelistData = useSelector((state) =>
        selectAllBalanceLIstData(state)
    );
    useEffect(() => {
        setOption(props.data);
        const data: any = props.data;
        const newData: any = {}
        setOption(data);
        let tagclass = '';
        if (data.Type == 'pay_in') {
            tagclass = 'tag_payin-tag'
            settagname('Pay In')
        } else if (data.Type == 'pay_out') {
            tagclass = 'tag_payout-tag'
            settagname('Pay Out')
        } else if (data.Type == 'fees') {
            tagclass = 'tag_fees-tag'
            settagname('Fees')
        }
        setTagclass(tagclass);
    }, [props.data]);
    useEffect(() => {
        if (balanceRender && balancelistData?.GET_PDF_SUCCESS !== undefined) {
            setBalanceRender(false);
            setBalance(balancelistData.GET_PDF_SUCCESS);
            setDatadesplay(false)
        }
    }, [balancelistData, balanceRender]);
    const onClose = () => {
        setShow(false);
        props.onClose(false);
    };
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    return (
        <>
            <Modal
                show={showModal}
                onHide={onClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby='ModalHeader'
                className='topup-modal trans-modal'
                id='afaFafffffFFff'
                centered
            >
                <ThemeProvider theme={theme}>
                <Box className="CloseContenar">
                    <IconButton onClick={() => {
                        onClose();
                    }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className="heding_contean">
                    <Typography>
                    Transaction Details
                    </Typography>
                </Box>
                </ThemeProvider>

                <Modal.Body>
                    <div className='trans-detail-containerX' >
                        <div className='trans-detail-container__table' >
                            {
                                (!!tagname) ?
                                    <div className='dflex b-bot'>
                                        <div className='left'>Type</  div>
                                        <div className='right text-capitalize tag'>
                                            <span className={`tag_color ${Tagclass}`}>
                                                {tagname}
                                            </span>
                                        </div>
                                    </div> : ""
                            }
                            {
                                (!!option.reference) ?
                                    <div className='dflex b-bot'>
                                        <div className='left'>Reference</div>
                                        <div className='right'>{option.reference}</div>
                                    </div> : ""
                            }
                            {
                                (!!option.payment_channel) ?
                                    <div className='dflex b-bot'>
                                        <div className='left'>Payment Channel</div>
                                        <div className='right'>{option.payment_channel}</div>
                                    </div> : ""
                            }
                            {
                                (!!option.transaction_date) ?
                                    <div className='dflex b-bot'>
                                        <div className='left'>Transaction Date</  div>
                                        <div className='right'>{moment.tz(option.transaction_date, "UTC").tz(moment.tz.guess()).format('D MMMM YYYY, h:mm A')}</div>
                                    </div> : ""
                            }
                            {
                                (!!option.transaction_amount) ?
                                    <div className='dflex b-bot'>
                                        <div className='left'>Transaction Amount</div>
                                        <div className='right'>{option.currency} {!!option.transaction_amount ?
                                            Number((parseFloat((option.transaction_amount))).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) : 0}</div>
                                    </div> : ""
                            }
                            {!!option.mobile && tagname == 'Pay In' && !!option.payee ?
                                <div className='dflex b-bot'>
                                    <div className='left'>Sender Name</div>
                                    <div className='right'>{option.payee}</div>
                                </div> : ""}
                            {!!option.mobile && tagname == 'Pay In'?
                           
                                <div className='dflex b-bot'>
                                    <div className='left'>Sender Number</div>
                                    <div className='right'>{!!option.mobile ? option.mobile : ""}</div>
                                </div>
                                : ""
                            }
                            {tagname == 'Pay Out' ?
                                (!!option.payee) ?
                                    <div className='dflex b-bot'>
                                        <div className='left'>Payee Name</div>
                                        <div className='right'>{option.payee}</div>
                                    </div> : "" : (!!option.beneficiary) ? <div className='dflex b-bot'>
                                        <div className='left'>To</div>
                                        <div className='right'>{tagname == 'Pay In' ? option.beneficiary : option.payee}</div>
                                    </div> : ""
                            }
                            {option.payment_channel == "M-Pesa Paybill" && tagname == 'Pay Out' && !!option.beneficiary_public_name ?
                                <div className='dflex b-bot'>
                                    <div className='left'>Beneficiary Name</div>
                                    <div className='right'>{option.beneficiary_public_name}</div>
                                </div> : ""}
                            {tagname == 'Fees' && !!option.beneficiary ?
                                <div className='dflex b-bot'>
                                    <div className='left'>Payee Name</div>
                                    <div className='right'>{option.beneficiary}</div>
                                </div> : ""}
                            {option.payment_channel == "M-Pesa Send Money" && tagname == 'Pay Out' && !!option.beneficiary_public_name ?
                                <div className='dflex b-bot'>
                                    <div className='left'>Beneficiary Name</div>
                                    <div className='right'>{option.beneficiary_public_name}</div>
                                </div> : ""}
                            {option.payment_channel == "M-Pesa Till" && tagname == 'Pay Out' && !!option.beneficiary ? <>

                                <div className='dflex b-bot'>
                                    <div className='left'>Beneficiary Name</div>
                                    <div className='right'>Merchant Till</div>
                                </div>
                            </> : ""}
                            {!!option.bank_name && tagname == 'Pay Out' && option.payment_channel == "Pesalink" ?
                                <div className='dflex b-bot'>
                                    <div className='left'>Bank Name</div>
                                    <div className='right'>{option.bank_name}</div>
                                </div> : ""}
                            {!!option.account_name && tagname == 'Pay Out' && option.payment_channel == "Pesalink" ?
                                <div className='dflex b-bot'>
                                    <div className='left'>Payee Name</div>
                                    <div className='right'>{option.account_name}</div>
                                </div> : ""}
                            {!!option.account_number && tagname == 'Pay Out' && option.payment_channel == "Pesalink" ?
                                <div className='dflex b-bot'>
                                    <div className='left'>Beneficiary Number</div>
                                    <div className='right'>{option.account_number}</div>
                                </div> : ""}

                            {!!option.mobile && tagname == 'Pay Out' && option.payment_channel == "M-Pesa Send Money" ?
                                <>
                                    <div className='dflex b-bot'>
                                        <div className='left'>Beneficiary Number</div>
                                        <div className='right'>{!!option.mobile ? option.mobile : ""}</div>
                                    </div></> : ""
                            }
                            {tagname == 'Pay Out' && option.payment_channel == 'M-Pesa Paybill' && !!option.paybill_number ?
                                <>
                                    <div className='dflex b-bot'>
                                        <div className='left'>Beneficiary Paybill Number</div>
                                        <div className='right'>{!!option.paybill_number ? option.paybill_number : ""}</div>
                                    </div></> : ""}
                            {tagname == 'Pay Out' && option.payment_channel == 'M-Pesa Paybill' && !!option.account_number ?
                                <>
                                    <div className='dflex b-bot'>
                                        <div className='left'>Account Number</div>
                                        <div className='right'>{!!option.account_number ?
                                            option.account_number : ""}</div>
                                    </div></> : ""}
                            {option.payment_channel == "M-Pesa Paybill" ||
                                option.payment_channel == "M-Pesa Till" ? (
                                <div className="dflex b-bot">
                                    <div className="left">Account Name</div>
                                    <div className="right">{option?.verified_account_name ?? "N/A"}</div>
                                </div>
                            ) : (
                                ""
                            )}
                            {tagname == 'Pay Out' && option.payment_channel == 'M-Pesa Till' && !!option.till_number ?
                                <>
                                    <div className='dflex b-bot'>
                                        <div className='left'>Beneficiary Number</div>
                                        <div className='right'>{!!option.till_number ? option.till_number : ""}</div>
                                    </div></> : ""}
                            {!!option.mobile && tagname == 'Pay Out' && !!user.entity.name ?
                                <div className='dflex b-bot'>
                                    <div className='left'>From</div>
                                    <div className='right'>{user.entity.name}</div>
                                </div> : ""}
                            {user?.role_name == 'Developer' ?
                                <>
                                    {tagname == 'Pay Out' || tagname == 'Pay In' ?
                                        <div className="dflex b-bot">
                                            <div className="left">Third Party Reference 1</div>
                                            <div className="right">{!!option.third_party_reference_1 ? option.third_party_reference_1 : "-"}</div>
                                        </div> : ""}
                                    {tagname == 'Pay Out' || tagname == 'Pay In' ?
                                        <div className="dflex b-bot">
                                            <div className="left">Third Party Reference 2</div>
                                            <div className="right">{!!option.third_party_reference_2 ? option.third_party_reference_2 : "-"}</div>
                                        </div> : ""}
                                </> : ""}
                            {
                                (!!option.invoice_no) ?
                                    <div className='dflex b-bot'>
                                        <div className='left'>Invoice No</div>
                                        <div className='right' style={{ cursor: "pointer", textDecoration: "underline", fontWeight: "bold" }} id='adaDdADddd' onClick={() => {
                                            if (!!option.payable_id) {
                                                window.location.href = `bills?id=${option.payable_id}`
                                            }
                                        }}>{option.invoice_no}</div>
                                    </div> : ""
                            }

                        </div>
                    </div>
                    <ThemeProvider theme={theme}>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose_twoo}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            sx={{ "& .MuiMenu-list": { padding: "0px" } }}
                        >
                            <div style={{ padding: "5px" }}>
                                <div style={{ height: "27px" }}>
                                    {!!balance && !!balance.data.path && datadesplay == false ?
                                        <a style={{ textDecoration: "none" }}
                                            href={!balance ? "" : balance.data.url}
                                            className='download_buttoun' target="_blank">Download PDF</a> :
                                        <a style={{ textDecoration: "none", cursor: "no-drop" }}
                                            className='download_buttoun'>Download PDF</a>}
                                </div>
                                <div style={{ height: "27px" }}>
                                    <WhatsappShareButton
                                        disabled={!!balance && !!balance.data.path && balanceRender == false ? false : true}
                                        url={`${process.env.REACT_APP_API_URL}/get-transaction-pdf-link?hash=${!balance ? "" : balance.data.path}${'\n'}To also use Niobi to easily track your finances and business payments, sign up here: https://app.niobi.co${'\n'}`}
                                        onClick={handleClose_twoo}
                                    >
                                        Send via WhatsApp
                                    </WhatsappShareButton>
                                </div>
                                <div style={{ height: "27px" }}>
                                    <EmailShareButton
                                        disabled={!!balance && !!balance.data.path && balanceRender == false ? false : true}
                                        url={`To also use Niobi to easily track your finances and business payments, sign up here: https://app.niobi.co${'\n'}`}
                                        body={`${process.env.REACT_APP_API_URL}/get-transaction-pdf-link?hash=${!balance ? "" : balance.data.path}${'\n'}`}
                                    >
                                        Send via Email
                                    </EmailShareButton>

                                </div>
                            </div>
                        </Menu>
                    </ThemeProvider>
                    <div className='trans-detail-container__btn-row'>
                        {/* {tagname == 'Pay In' ? <Button className='trans-detail-container__btn-row__green'
                            onClick={() => {
                                onClose()
                                navigate("/PaymentInvoice")
                                }}>
                            Link to an invoice
                        </Button> : <></>} */}
                        <Button className='trans-detail-container__btn-row__green'
                            onClick={handleClick}
                        >Share</Button>
                    </div>
                </Modal.Body>
            </Modal >

        </>
    )
}