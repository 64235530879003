import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './Balance.scss';
import { PayableGrid } from './components/PayableGrid/PayableGrid';
import { TopupModal } from './components/topup-modal/Topup';
import { TopupModal_two } from './components/topup-modal/Topup_two';
import TopupDetail from './components/TopupDetail/TopupDetail';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUser, isUserAuthenticated, authHeader } from '../../../../_helpers';
import { selectAllData } from '../../../../_reducers/dashboard.reducer';
import { selectAllBalanceLIstData } from '../../../../_reducers/balance.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from '../../../../_actions';
import moment from 'moment';
import DatePickerll from 'react-datepicker';
import BalanceTable from './BalanceTable';
import { TransactionDetails } from './components/TransactionDetails/TransactionDetails';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { Virefily_account } from './components/TransactionDetails/virefiy_account';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { selectAllCounterpartsListData } from '../../../../_reducers/counterparts.reducer';
import { counterpartsActions } from '../../../../_actions/counterparts.actions';
import Menu from '@mui/material/Menu';
import Pending_Transactions_popup from '../../../app/Layout/Dashboard/components/Pending_Transactions_popup/Pending_Transactions_popup';
import Topup_balk_data from './components/topup-modal/csv_balk';
import amplitude from 'amplitude-js';
import { getProperties } from '../../../../_helpers';
import SelectPopup from './components/download/selectPopup';
import Popup from './components/download/popup';
import DatePopup from './components/download/datepopup';
import Select_source_account from './components/Select_source_account';
import SelectMethod from './components/topup-modal/selectMethod';
import posthog from 'posthog-js';
import ChooseCurrency from './components/Choose_currency/ChooseCurrency';
import { paymentJourneyActions } from '../../../../_actions/paymentJourney.actions';
import { selectPaymentJourneyData } from '../../../../_reducers/paymentJourney.reducer';
import { Box, CircularProgress, MenuItem, Select } from '@mui/material';
import {SkeletonCom} from '../Skeleton/Skeleton';


const SearchIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 13L10.0748 10.1341M11.6667 6.33334C11.6667 9.27886 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27886 1 6.33334C1 3.38782 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38782 11.6667 6.33334Z"
      stroke="#111111"
      stroke-width="1.1875"
      stroke-miterlimit="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const Balance = () => {
  const user = getUser()?.data ?? '';
  const navigate = useNavigate();
  const [balance, setBalance] = useState<any>([]);
  const [balanceList, setBalanceList] = useState<any>([]);
  const [balanceListtwo, setBalanceListtwo] = useState<any>([]);
  const [datatabal, setDatatebal] = useState<any>([]);
  const [render, setRender] = useState(true);
  const [balanceRender, setBalanceRender] = useState(false);
  const [balanceListRender, setBalanceListRender] = useState(true);
  const sm = moment().subtract(1, 'M').format('YYYY-MM-DD HH:mm:ss');
  const [startDate, setStartDate] = useState();
  const [startDateExp, setStartDateExp] = useState();
  const fm = moment(sm).add(1, 'M').format('YYYY-MM-DD HH:mm:ss');
  const [endDate, setEndDate] = useState();
  const [endDateExp, setEndDateExp] = useState();
  const [loader, setLoader] = useState(true);
  const [csvUrl, setCsvUrl] = useState<any>([]);
  const [pdfUrl, setPdfUrl] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const data = useSelector((state) => selectAllData(state));
  const [tagsList, setTagsList] = useState<any>([]);
  const [tagsListRender, setTagsListRender] = useState(true);
  const [verefire_data, setVirefay_data] = useState(false);
  const [select_amount, setSelect_amount] = useState<any>(
    ''
  );
  const [SelectCurrency, setSelectCurrency] = useState<any>(select_amount)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl_unsettled, setAnchorEl_unsettled] = React.useState<null | HTMLElement>(null);
  const [currencySelect, setCurrencySelect] = React.useState(false)
  const [selectCurrencyPopup, setSelectCurrencyPopup] = useState<any>('KES')
  const open = Boolean(anchorEl);
  const open_unsettled = Boolean(anchorEl_unsettled);
  console.log(SelectCurrency, select_amount, 'SelectCurrencySelectCurrency');
  // console.log(SelectCurrency,select_amount, 'SelectCurrencySelectCurrency');

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currencyParam = params.get('currency');


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose_popup = () => {
    setAnchorEl(null);
  };
  const handleClick_unsettled = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl_unsettled(event.currentTarget);
  };
  const handleClose_unsettled = () => {
    setAnchorEl_unsettled(null);
  };
  const counterpartsListData = useSelector((state) =>
    selectAllCounterpartsListData(state)
  );
  const properties = getProperties();
  useEffect(() => {
    setSelectCurrency(select_amount)
  }, [select_amount])
  useEffect(() => {
    // console.log('useEffectCalled Top', tagsListRender, counterpartsListData);
    if (tagsListRender && counterpartsListData?.GETTAGS_SUCCESS !== undefined) {
      let options: any = [];
      if (counterpartsListData.GETTAGS_SUCCESS.length > 0) {
        counterpartsListData.GETTAGS_SUCCESS.map((item: any, ind: number) => {
          options.push({
            id: item.id,
            name: item.name,
          });
        });
      }
      setTagsListRender(false);
      setTagsList(options);
    }
  }, [counterpartsListData, tagsListRender]);
  const balancelistData = useSelector((state) =>
    selectAllBalanceLIstData(state)
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (balanceRender && data?.GET_DASHBOARD_BALANCE_SUCCESS !== undefined) {
      setBalanceRender(false);
      setBalance(data.GET_DASHBOARD_BALANCE_SUCCESS);
      setLoderapi(false);
      setLoader(false);
      // console.log(data, 'data.GET_DASHBOARD_BALANCE_SUCCESS');
    }
  }, [data, balanceRender]);
  // console.log(csvUrl, 'csvUrlcsvUrl');

  useEffect(() => {
    if (balanceListRender && balancelistData?.GETBALANCE_REQUESTTWO == false) {
      // console.log('i m inside');
      setBalanceListRender(false);
      setBalanceListtwo(
        !!balancelistData.GETBALANCE_SUCCESSTWO
          ? balancelistData.GETBALANCE_SUCCESSTWO.sort(
            (a: any, b: any) => b.id - a.id
          )
          : balancelistData.GETBALANCE_SUCCESSTWO
      );
    }
  }, [balancelistData, balanceListRender]);
  useEffect(() => {
    if (user && isUserAuthenticated()) {
    } else if (!user) {
      navigate('/login');
    }
  }, [user]);
  const [showTopup, setShowTopup] = useState(false);
  const [showTopup_two, setShowTopup_two] = useState(false);
  const [topUp, setTopup] = useState(false);
  const [showBalanceGrid, setShowBalanceGrid] = useState(true);
  const [topUpMethod, setTopupMethod] = useState(false);
  const [paymentGrid, setPaymentGrid] = useState(false);
  const [search, setSearch] = React.useState('');
  const [pageneshn, setPagelist] = useState(1);
  const [remove, setRemovedata] = useState(10);
  const [showTransMoodal, setshowTransMoodal] = React.useState(false);
  const [requestRender, setrequestRender] = useState(false);
  const [responseRender, setresponseRender] = useState(false);
  const [transData, settransData] = React.useState({});
  const [loderinapi, setLoderapi] = React.useState(false);
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(10);
  const [openlist, setOpenlist] = React.useState(false);
  const [categ, setCateg] = React.useState('');
  const [topup_balk_data, setTopup_balk_data] = React.useState(false);
  const [counterpartsList_two, setCounterpartsList_two] = useState<any>();
  const [counterpartsListRender, setCounterpartsListRender] = useState(false);
  const [select_data, setSelect_data] = useState('');
  const [data_type, setType_data] = useState('');
  const [recent, setRecent] = useState<any>();
  const [Dounload_popup_date, setDounload_popup_date] = useState(false);
  const [Dounload_popup, setDounload_popup] = useState(false);
  const [Dounload_popup_pdf, setDounload_popup_pdf] = useState(false);
  const [type_select, setType_select] = useState<any>([
    {
      name: 'Pay Out',
      id: 'pay_out',
    },
    {
      name: 'Pay In',
      id: 'pay_in',
    },
    {
      name: 'Fees',
      id: 'fees',
    },
  ]);
  const [selectMethod, setSelectMethod] = useState(false)
  const [select_popup, setSelect_popup] = useState("")
  const [date_popup, setDate_popup] = useState(false)
  const [countryList, setCountrtList] = useState([]);
  const [startgetapi, setStartgetapi] = useState(true);
  const [RenderData, setRenderData] = useState(true)
  const [currencyAndMethodData, setCurrencyAndMethodData] = useState("");
  const[Balanceloader,setBalanceloader]=useState(false)

  const today = new Date();
  // console.log(select_popup, 'select_popup');
  const dataList = useSelector((state) => selectPaymentJourneyData(state));



  const getCsvurl = async (datalist: any) => {

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
    };
    const stateDateApi = !!datalist.startdate
      ? moment(datalist.startdate).format('YYYY-MM-DD')
      : '';
    const endDateApi = !!datalist.enddate
      ? moment(datalist.enddate).format('YYYY-MM-DD')
      : '';
    setLoader(true);
    const response = await axios
      .get(
        `${process.env.REACT_APP_API_URL}/export-csv?search=${datalist.search}&start-date=${stateDateApi}&end-date=${endDateApi}`,
        requestOptions
      )
      .then((response) => {
        if (response.data.success == true) {
          setCsvUrl(response.data);
          setPdfUrl(csvUrl.pdf);
          setLoader(false);
        } else {
          setLoader(false);
        }
      });
  };

  const getBalanceList = async (datalist: any) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
    };
    const stateDateApi = !!datalist.startdate
      ? moment(datalist.startdate).format('YYYY-MM-DD')
      : '';
    const endDateApi = !!datalist.enddate
      ? moment(datalist.enddate).format('YYYY-MM-DD')
      : '';
    const category = !!datalist.category ? datalist.category : '';

    var pagelimit = 10;
    const searchString = `?search=${datalist.search}&start-date=${stateDateApi}&end-date=${endDateApi}&payee=${datalist.payee}&type=${datalist.type}&page=${datalist.pagedata}&limit=${pagelimit}&category=${category}`;
    setLoderapi(true);
    const response = await axios
      .get(
        `${process.env.REACT_APP_API_URL}${user?.role_name == 'Developer'
          ? '/get-unified-payments'
          : '/wallet/get-wallet-data'
        }?search=${datalist.search
        }&category=${category}&start-date=${stateDateApi}&end-date=${endDateApi}&payee=${datalist.payee
        }&currency=${datalist.select_amount}&type=${datalist.type}&page=${datalist.pagedata
        }&limit=${pagelimit}`,
        requestOptions
      )
      .then((response) => {
        if (response.data.success == true) {
          setDatatebal(response.data.data.data);
          setTotalCount(response.data.data.total);
          setTimeout(() => {
            setLoderapi(false);
          }, 2000);
        } else {
          setTimeout(() => {
            setLoderapi(false);
          }, 6000);
        }
      });
  };
  useEffect(() => {
    console.log(isUserAuthenticated() && render && user?.role_name !== 'Developer' && !!select_amount, select_amount, isUserAuthenticated(), user?.role_name, render, "dszfgszdfszfff");

    // console.log(isUserAuthenticated() && render && user?.role_name !== 'Developer' && !!select_amount,select_amount,isUserAuthenticated(),user?.role_name,render,"dszfgszdfszfff");

    if (isUserAuthenticated() && render && user?.role_name !== 'Developer' && !!select_amount) {
      var serdata: any = {
        search: search,
        startdate: startDate,
        enddate: endDate,
        payee: select_data,
        type: data_type,
        pagedata: pageneshn,
        category: categ,
        select_amount: select_amount,
      };
      // getBalanceList(serdata);
      getCsvurl(serdata);
      // dispatch(dashboardActions.getBalance('/wallet/get-balance') as any);
      dispatch(counterpartsActions.getAllCounterparts(setCounterpartsListRender) as any);
      dispatch(counterpartsActions.getAllTags() as any);
      setBalanceListRender(true);
      setrequestRender(true);
      setLoader(true);
      setRender(false);
    }
  }, [
    render,
    startDate,
    endDate,
    select_data,
    data_type,
    pageneshn,
    select_amount
  ]);
  useEffect(() => {
    if (
      counterpartsListRender &&
      counterpartsListData?.GETCOUNTERPARTS_SUCCESS !== undefined
    ) {
      setCounterpartsList_two(counterpartsListData?.GETCOUNTERPARTS_SUCCESS);
      setCounterpartsListRender(false);
    }
  }, [counterpartsListData, counterpartsListRender]);
  useEffect(() => {
    if (RenderData) {
      setRenderData(false);
      dispatch(paymentJourneyActions.getCountriesBalances() as any);
      setLoderapi(true)
    }
  }, [RenderData]);
  useEffect(() => {
    if (startgetapi && dataList?.GETCOUNTRIES_BALANCES_SUCCESS !== undefined) {
      setCountrtList(dataList?.GETCOUNTRIES_BALANCES_SUCCESS);
      setLoderapi(false)
      setLoader(false);
      setStartgetapi(false);
    }
  }, [startgetapi, dataList]);
  useEffect(() => {
    if (requestRender && balancelistData?.GETBALANCE_REQUEST == true) {
      setLoader(balancelistData.GETBALANCE_REQUEST);
      setresponseRender(true);
      setrequestRender(false);
    }
  }, [balancelistData, requestRender]);

  useEffect(() => {
    if (responseRender && balancelistData?.GETBALANCE_REQUEST == false) {
      if (balancelistData.GETBALANCE_SUCCESS.data != balanceList) {
        setLoader(false);
        setrequestRender(true);
        setresponseRender(false);
        setBalanceList(
          !!balancelistData.GETBALANCE_SUCCESS.data
            ? balancelistData.GETBALANCE_SUCCESS.data
            : []
        );
      }
    }
  }, [balancelistData, responseRender]);
  useEffect(() => {
    if (!!state) {
      const { type, color } = state; // Read values passed on state
      if (!!type) {
        // console.log(type, 'typeinside ');
        if (type == 'topup') {
          setShowTopup(true);

          // console.log('showTopup', showTopup);
        }
      }
    }
  }, [state]);
  useEffect(() => {
    const popup = searchParams.get('popup');
    const currancy = searchParams.get('currency');
    if (!!popup) {
      setPaymentGrid(true);
      setShowBalanceGrid(false);
      setTopup(false);
    } else {
    }
    if (!!currancy) {
      setSelect_amount(currancy)
      setSelectCurrencyPopup(currancy)
      onSavecurrancy(currancy)
    }
  }, [searchParams]);

  function doneTyping() {
    var serdata: any = {
      search: search,
      startdate: startDate,
      enddate: endDate,
      payee: select_data,
      type: data_type,
      pagedata: pageneshn,
      category: categ,
      select_amount: select_amount,
    };
    getBalanceList(serdata);
    setBalanceListRender(true);
    setrequestRender(true);
    setLoader(true);
  }

  useEffect(() => {
    if (!!select_amount) {
      const timeoutId = setTimeout(() => {
        doneTyping();
        // console.log('usereffect testing');
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [search]);

  // console.log(user, 'userWWW');
  const theme = createTheme({
    palette: {
      primary: {
        main: '#000',
      },
    },
  });
  const onSavecurrancy = (data: any) => {
    var serdata: any = {
      search: search,
      startdate: startDate,
      enddate: endDate,
      payee: select_data,
      type: data_type,
      pagedata: pageneshn,
      category: categ,
      select_amount: data,
    };
    dispatch(
      dashboardActions.getBalance(`/wallet/get-currency-balance/${data}`, setBalanceRender,setBalanceloader) as any
    );
    getBalanceList(serdata);
    getCsvurl(serdata);
    setTimeout(() => {
      // setBalanceRender(true);
      setLoderapi(true);
    }, 3000);
  };
  const onSavecurrancy_chenge = (data: any) => {
    var serdata: any = {
      search: search,
      startdate: startDate,
      enddate: endDate,
      payee: select_data,
      type: data_type,
      pagedata: pageneshn,
      category: categ,
      select_amount: data,
    };
    getBalanceList(serdata);
    getCsvurl(serdata);
    setTimeout(() => {
      // setBalanceRender(true);
    }, 3500);
  }
  useEffect(() => {
    if (!!select_amount) {
      var serdata: any = {
        search: search,
        startdate: startDate,
        enddate: endDate,
        payee: select_data,
        type: data_type,
        pagedata: pageneshn,
        category: categ,
        select_amount: select_amount,
      };
      getBalanceList(serdata);
      getCsvurl(serdata);
      setTimeout(() => {
        // setBalanceRender(true);
      }, 3500);
    }
  }, [startDate, endDate, pageneshn])

  useEffect(() => {
    dispatch(dashboardActions.getDashboardCurrencyData() as any)
  }, [dispatch])



  useEffect(() => {
    if (data && data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS !== undefined) {
      setCurrencyAndMethodData(data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS.data);
    }
  }, [data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS])


  // console.log("counterpartsListRender ==>",counterpartsListRender);



  // handle currency-get
  const handleCountrySelect = (data: any) => {
    dispatch(paymentJourneyActions.getCountriesByCurrency(data) as any)
    dispatch(paymentJourneyActions.getMobileMethodsByCurrency(data) as any)
    dispatch(paymentJourneyActions.getBankMethodsByCurrency(data) as any)
    dispatch(paymentJourneyActions.getBankDetailByCurrency(data) as any)
  }


  // console.log("balance currencyAndMethodData =>", currencyAndMethodData, countryList);

  console.log("balance dash =>", balance);




  return (
    <>
      <ThemeProvider theme={theme}>
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <div
            className="document-verified"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '598px',
              height: '250px',
              flexDirection: 'column',
              background: '#FFFFFF',
              boxShadow: '0px 4px 12px rgba(117, 114, 114, 0.25)',
              borderRadius: '8px',
              padding: '48px 64px',
              margin: '0px 0px',
              textAlign: 'center',
            }}
          >
            <div className="document-verified__desc">
              <p style={{ paddingTop: '17px', fontSize: '14px' }}>
                {csvUrl?.message}
              </p>
              <br />
            </div>
            <Button
              id="Go_back"
              className="document-verified__btn"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </div>
        </Modal>

        {showBalanceGrid && (
          <>

            {/* {loderinapi && (
              <Box
                sx={{
                  display: 'flex',
                  position: 'absolute',
                  top: 150,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 10,
                }}
              >
                <CircularProgress color="success" />
              </Box>

            )} */}
            {/* {loader && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
    <CircularProgress color="success" />
  </Box>} */}
            {/* </ThemeProvider> */}
            <div className="balance">
              <div className="balance__header">
                <div className="balance__header__name">My Accounts</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <>
                    {!!select_amount ? (
                      <div
                        className="header"
                        style={{ fontSize: '14px', paddingRight: "15px", paddingTop: "10px" }}
                        id="totalBalance_header"
                      >
                        Select currency{' '}

                      </div>) : ("")}
                    {!!select_amount ? (
                      // <Button
                      //   style={{ marginRight: '10px' }}
                      //   className="balance__white-btn"
                      //   onClick={() => {
                      //     setSelect_amount('');
                      //     setCardTypeSelect("ALL")
                      //   }}
                      // >
                      //   Change Currency{' '}
                      // </Button>
                      <ThemeProvider theme={theme}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className="selectBox"
                          style={{ marginRight: "12px", marginTop: "10px" }}
                          value={selectCurrencyPopup}

                          onChange={(e: any) => {
                            setSelectCurrencyPopup(e.target.value)
                            setSelectCurrency(e.target.value)
                            setSelect_amount(e.target.value)
                            onSavecurrancy(e.target.value)
                            navigate(`/balance?currency=${e.target.value}`);
                            localStorage.setItem("currency", e.target.value)
                            handleCountrySelect(e.target.value);
                          }}
                        >
                          {Array.isArray(currencyAndMethodData) && currencyAndMethodData?.map((item: any, index) => {
                            return (
                              <MenuItem
                                value={item.currency}
                                key={index}>
                                <Box className="MenuItemBox" >
                                  <img
                                    loading="lazy"
                                    width="25"
                                    src={item.flag}
                                    alt=""
                                  />
                                  <p>
                                    {item.currency}
                                  </p>
                                </Box>
                              </MenuItem>

                            )
                          })}
                        </Select>
                      </ThemeProvider>
                    ) : (
                      ''
                    )}
                    {/* {!!select_amount ?
                    <Button
                      style={{ marginRight: '10px' }}
                      className="balance__white-btn"
                      onClick={() => {
                        setSelect_amount('');
                        navigate('/balance')
                      }}
                    >
                      Change Currency{' '}
                    </Button> : ""} */}

                    <>
                      {!!user && user.entity.status == 'UNVERIFIED' && !!select_amount ? (
                        <Button
                          className="balance__white-btn"
                          onClick={() => {
                            setVirefay_data(true);
                          }}
                        >
                          Receive Payment{' '}
                        </Button>
                      ) :
                        !!select_amount && !!user &&
                          !!user.role_name &&
                          (user.role_name == 'Accountant' || user.role_name == 'Admin' || user.role_name == 'Director') ? (
                          <Button
                            className="balance__white-btn"
                            onClick={() => {
                              const event = 'New Top Up Via Balance';
                              const eventProperties = {
                                userId:
                                  !!properties && !!properties.user_id
                                    ? properties.user_id
                                    : null,
                                userName:
                                  !!properties && !!properties.name
                                    ? properties.name
                                    : null,
                                email:
                                  !!properties && !!properties.email
                                    ? properties.email
                                    : null,
                                pageUrl: window.location.href,
                              };

                              amplitude
                                .getInstance()
                                .logEvent(event, eventProperties);
                              posthog.capture(event, eventProperties);
                              setShowTopup(true);
                            }}
                          >
                            Receive Payment{' '}
                          </Button>
                        ) : !!select_amount ? (
                          <Button
                            className="balance__white-btn"
                            style={{ cursor: 'not-allowed' }}
                          >
                            Receive Payments{' '}
                          </Button>
                        ) : ""}
                    </>





                    {!!user && user.entity.status == 'UNVERIFIED' && !!select_amount ? (
                      <Button
                        className="balance__save-btn"
                        onClick={() => {
                          setVirefay_data(true);
                        }}
                      >
                        Send Payment
                      </Button>
                    ) : !!select_amount && !!user &&
                      !!user.role_name &&
                      (user.role_name == 'Developer' || user.role_name == 'Approver' || user.role_name == 'Viewer') ? (
                      <Button
                        className="balance__save-btn"
                        style={{ cursor: 'no-drop' }}
                      >
                        Send Payment
                      </Button>
                    ) : balance[0]?.total_balance == 0 ? (
                      <Button
                        className="balance__save-btn"
                        style={{ cursor: 'no-drop' }}
                      >
                        Send Payment
                      </Button>
                    ) : !!select_amount ? (
                      <Button
                        className="balance__save-btn"
                        onClick={() => {

                          // if (select_amount == "KES") {
                          //   setShowTopup_two(true)
                          //   setCurrencySelect(false)
                          // } else if (select_amount == "USD") {
                          //   setSelectMethod(true)
                          //   setCurrencySelect(false)
                          // } else {
                          //   setCurrencySelect(true)
                          if (select_amount == "KES") {
                            setShowTopup_two(true)
                            setCurrencySelect(false)

                          } else if (select_amount == "USD") {
                            navigate(`/payment-journey?currency=USD`)

                            // setSelectMethod(true)
                            // setCurrencySelect(false)
                          }
                          else if (select_amount != "KES") {
                            navigate(`/payment-journey?currency=${select_amount}`)
                          }
                          // else {
                          //   setCurrencySelect(true)
                          //   setShowTopup_two(true)
                          // }

                        }}
                      >
                        Send Payment
                      </Button>
                    ) : ""}
                  </>
                </div>
              </div>
              <ThemeProvider theme={theme}>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose_popup}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <Pending_Transactions_popup
                    Heading={'Pending Transactions'}
                    transactions={
                      select_amount == 'KES'
                        ? balance[0]?.transactions?.niobi_transactions
                        : balance[0]?.transactions?.niobi_transactions
                    }
                  />
                </Menu>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl_unsettled}
                  open={open_unsettled}
                  onClose={handleClose_unsettled}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <Pending_Transactions_popup
                    Heading={'Unsettled Transactions'}
                    transactions={
                      select_amount == 'KES'
                        ? balance[0]?.transactions?.unsettled_transactions
                        : select_amount == 'USD' && !!balance
                          ? balance[0]?.transactions?.unsettled_transactions
                          : ''
                    }
                  />
                </Menu>
              </ThemeProvider>
              {!!user && !!user.role_name && user.role_name == 'Associate' ? (
                <>
                  <div className="add_new_text_p">
                    You do not have sufficient permissions for this role
                  </div>
                </>
              ) : !select_amount ? (
                <ThemeProvider theme={theme}>
                  <ChooseCurrency
                    currencyAndMethodData={currencyAndMethodData}
                    onSavecurrancy={onSavecurrancy}
                    setCurrancylist={setSelect_amount}
                  />
                </ThemeProvider>
              ) : (
                <>
                  <div className="card_select_data">
                    <div
                      className="main_div_balance_data"
                      style={{ display: 'flex' }}
                    >
                      {/* {user?.role_name == 'Developer'||user?.role_name == 'Director' ? ( */}
                      {balance?.map((field: any) => {
                        return (
                          <SkeletonCom loader={loderinapi}
                            element={
                              <div
                                //  onClick={() => {

                                //  }}
                                style={{ cursor: 'pointer' }}
                                className={'balance__card_TWO'}
                              >
                                <div className={'balance__card_TWO__row'}>
                                  <span className={'balance__card_TWO__unit'}>
                                    {field.currency}
                                  </span>
                                  <span className={'balance__card_TWO__amount'}>
                                    {!!field.total_balance
                                      ? (
                                        field.total_balance / 100
                                      ).toLocaleString('en', {
                                        minimumFractionDigits: 2,
                                      })
                                      : '0.00'}
                                  </span>
                                </div>
                                <div
                                  className="d-flex"
                                  style={
                                    user?.role_name == 'Developer'
                                      ? {
                                        justifyContent: 'space-between',
                                        marginTop: '0px',
                                        paddingTop: '4px',
                                      }
                                      : {
                                        justifyContent: 'space-between',
                                        marginTop: '20px',
                                        paddingTop: '4px',
                                      }
                                  }
                                >
                                  <div className="left" style={{ fontSize: '13px' }}>
                                    Available Balance
                                  </div>
                                  <div className="right" style={{ fontSize: '13px' }}>
                                    <span>{field.currency}</span>{' '}
                                    {!!field?.available_balance
                                      ? (
                                        field?.available_balance / 100
                                      ).toLocaleString('en', {
                                        minimumFractionDigits: 2,
                                      })
                                      : '0.00'}
                                  </div>
                                </div>
                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: 'space-between',
                                    paddingTop: '1px',
                                  }}
                                >
                                  <div className="left" style={{ fontSize: '13px' }}>
                                    Pending Transactions{' '}
                                    <Button
                                      className="button_iconpanding"
                                      style={
                                        select_amount == 'KES'
                                          ? { backgroundColor: '#fff' }
                                          : { backgroundColor: '#F2F8F7' }
                                      }
                                      onClick={handleClick}
                                    >
                                      <InfoIcon></InfoIcon>
                                    </Button>
                                  </div>
                                  <div className="right" style={{ fontSize: '13px' }}>
                                    <span>{field?.currency}</span>{' '}
                                    {!!field?.on_hold_balance
                                      ? (
                                        field.on_hold_balance / 100
                                      ).toLocaleString('en', {
                                        minimumFractionDigits: 2,
                                      })
                                      : '0.00'}
                                  </div>
                                </div>
                                {user?.role_name == 'Developer' ? (
                                  <div
                                    className="d-flex"
                                    style={{
                                      justifyContent: 'space-between',
                                      paddingTop: '1px',
                                    }}
                                  >
                                    <div className="left" style={{ fontSize: '13px' }}>
                                      Unsettled Transactions{' '}
                                      <Button
                                        className="button_iconpanding"
                                        onClick={handleClick_unsettled}
                                        style={
                                          select_amount == 'KES'
                                            ? { backgroundColor: '#fff' }
                                            : { backgroundColor: '#F2F8F7' }
                                        }
                                      >
                                        <InfoIcon></InfoIcon>
                                      </Button>
                                    </div>
                                    <div className="right" style={{ fontSize: '13px' }}>
                                      <span>{field?.currency}</span>{' '}
                                      {!!field?.unsettled_balance
                                        ? (
                                          field?.unsettled_balance / 100
                                        ).toLocaleString('en', {
                                          minimumFractionDigits: 2,
                                        })
                                        : '0.00'}
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>}></SkeletonCom>
                        )
                      })}


                      {/* // ) 
                    // : (
                    //   <>
                    //     {balance?.map((field: any) => {
                    //       return (
                    //         <>
                    //           <div
                    //             onClick={() => {
                    //               setSelect_amount(field.currency);
                    //               onSavecurrancy_chenge(field.currency)
                    //             }}
                    //             style={{
                    //               cursor: 'pointer',
                    //               marginRight: '10px',
                    //             }}
                    //             className={
                    //               select_amount == field.currency
                    //                 ? 'balance__card_TWO'
                    //                 : 'balance__card'
                    //             }
                    //           >
                    //             <div
                    //               className={
                    //                 select_amount == field.currency
                    //                   ? 'balance__card_TWO__row'
                    //                   : 'balance__card__row'
                    //               }
                    //             >
                    //               <span
                    //                 className={
                    //                   select_amount == field.currency
                    //                     ? 'balance__card_TWO__unit'
                    //                     : 'balance__card__unit'
                    //                 }
                    //               >
                    //                 {field.currency}
                    //               </span>
                    //               <span
                    //                 className={
                    //                   select_amount == field.currency
                    //                     ? 'balance__card_TWO__amount'
                    //                     : 'balance__card__amount'
                    //                 }
                    //               >
                    //                 {!!field.total_balance
                    //                   ? (
                    //                     field.total_balance / 100
                    //                   ).toLocaleString('en', {
                    //                     minimumFractionDigits: 2,
                    //                   })
                    //                   : '0.00'}
                    //               </span>
                    //             </div>
                    //             <div
                    //               className="d-flex"
                    //               style={
                    //                 user?.role_name == 'Developer'
                    //                   ? {
                    //                     justifyContent: 'space-between',
                    //                     marginTop: '0px',
                    //                     paddingTop: '4px',
                    //                   }
                    //                   : {
                    //                     justifyContent: 'space-between',
                    //                     marginTop: '20px',
                    //                     paddingTop: '4px',
                    //                   }
                    //               }
                    //             >
                    //               <div
                    //                 className="left"
                    //                 style={{ fontSize: '13px' }}
                    //               >
                    //                 Available Balance
                    //               </div>
                    //               <div
                    //                 className="right"
                    //                 style={{ fontSize: '13px' }}
                    //               >
                    //                 <span>{field?.currency}</span>{' '}
                    //                 {!!field?.available_balance
                    //                   ? (
                    //                     field?.available_balance / 100
                    //                   ).toLocaleString('en', {
                    //                     minimumFractionDigits: 2,
                    //                   })
                    //                   : '0.00'}
                    //               </div>
                    //             </div>
                    //             <div
                    //               className="d-flex"
                    //               style={{
                    //                 justifyContent: 'space-between',
                    //                 paddingTop: '1px',
                    //               }}
                    //             >
                    //               <div
                    //                 className="left"
                    //                 style={{ fontSize: '13px' }}
                    //               >
                    //                 Pending Transactions{' '}
                    //                 <Button
                    //                   className="button_iconpanding"
                    //                   style={
                    //                     select_amount == 'KES'
                    //                       ? { backgroundColor: '#fff' }
                    //                       : { backgroundColor: '#F2F8F7' }
                    //                   }
                    //                   onClick={handleClick}
                    //                 >
                    //                   <InfoIcon></InfoIcon>
                    //                 </Button>
                    //               </div>
                    //               <div
                    //                 className="right"
                    //                 style={{ fontSize: '13px' }}
                    //               >
                    //                 <span>{field?.currency}</span>{' '}
                    //                 {!!field?.on_hold_balance
                    //                   ? (
                    //                     field.on_hold_balance / 100
                    //                   ).toLocaleString('en', {
                    //                     minimumFractionDigits: 2,
                    //                   })
                    //                   : '0.00'}
                    //               </div>
                    //             </div>
                    //             {user?.role_name == 'Developer' ? (
                    //               <div
                    //                 className="d-flex"
                    //                 style={{
                    //                   justifyContent: 'space-between',
                    //                   paddingTop: '1px',
                    //                 }}
                    //               >
                    //                 <div
                    //                   className="left"
                    //                   style={{ fontSize: '13px' }}
                    //                 >
                    //                   Unsettled Transactions{' '}
                    //                   <Button
                    //                     className="button_iconpanding"
                    //                     onClick={handleClick_unsettled}
                    //                     style={
                    //                       select_amount == field.currency
                    //                         ? { backgroundColor: '#fff' }
                    //                         : { backgroundColor: '#F2F8F7' }
                    //                     }
                    //                   >
                    //                     <InfoIcon></InfoIcon>
                    //                   </Button>
                    //                 </div>
                    //                 <div
                    //                   className="right"
                    //                   style={{ fontSize: '13px' }}
                    //                 >
                    //                   <span>{field?.currency}</span>{' '}
                    //                   {!!field?.unsettled_balance
                    //                     ? (
                    //                       field?.unsettled_balance / 100
                    //                     ).toLocaleString('en', {
                    //                       minimumFractionDigits: 2,
                    //                     })
                    //                     : '0.00'}
                    //                 </div>
                    //               </div>
                    //             ) : (
                    //               ''
                    //             )}
                    //           </div>
                    //         </>
                    //       );
                    //     })}
                    //   </>
                    // )} */}
                    </div>

                  </div>
                  <div className="balance__search-row">
                    <div className="dflex">
                      <SkeletonCom loader={loderinapi}
                        element={
                          <div
                            className="conteanaha_data_ketete"
                            style={{ width: '16%' }}
                          >
                            <InputGroup className="balance__filter-wrapper input">
                              <InputGroup.Text id="basic-addon1" className="icon">
                                <SearchIcon />
                              </InputGroup.Text>
                              <Form.Control
                                className="balance-search-input"
                                placeholder="Search by amount"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                disabled={loderinapi}
                                value={search}
                                onChange={(event) => {
                                  console.log('testing');
                                  if (event.target.value == '') {
                                    setSearch(event.target.value);
                                  } else {
                                    setSearch(event.target.value);
                                  }
                                }}
                              />
                            </InputGroup>
                          </div>}></SkeletonCom>
                      {!!user &&
                        user.role_name &&
                        user.role_name == 'Developer' ? (
                        ''
                      ) : (
                        <SkeletonCom loader={loderinapi}
                          element={
                            <div
                              className="conteanaha_data_ketete"
                              style={{ width: '14%' }}
                            >
                              <div className=" balance_cat">
                                <Form.Select
                                  value={categ}
                                  onChange={(e: any) => {
                                    setCateg(e.target.value);
                                    var serdata: any = {
                                      search: search,
                                      startdate: startDate,
                                      enddate: endDate,
                                      payee: select_data,
                                      type: data_type,
                                      pagedata: pageneshn,
                                      category: e.target.value,
                                      select_amount: select_amount,
                                    };
                                    getBalanceList(serdata);
                                  }}
                                >
                                  <option selected value="">
                                    Select category
                                  </option>
                                  {tagsList?.map(
                                    (arrayElement: any, index: number) => {
                                      return (
                                        <option
                                          value={arrayElement.name}
                                          key={index + 1}
                                        >
                                          {arrayElement.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </Form.Select>
                              </div>
                            </div>}></SkeletonCom>
                      )}
                      <SkeletonCom loader={loderinapi}
                        element={
                          <div
                            className="conteanaha_data_ketete"
                            style={{ width: '14%' }}
                          >
                            <div className=" balance_cat">
                              <Form.Select
                                name="supplier_id"
                                onChange={(e: any) => {
                                  setType_data(e.target.value);
                                  var serdata: any = {
                                    search: search,
                                    startdate: startDate,
                                    enddate: endDate,
                                    payee: select_data,
                                    type: e.target.value,
                                    pagedata: pageneshn,
                                    category: categ,
                                    select_amount: select_amount,
                                  };
                                  getBalanceList(serdata);
                                }}
                                value={data_type}
                              >
                                <option selected value="">
                                  Select type
                                </option>
                                {type_select?.map(
                                  (arrayElement: any, index: number) => {
                                    return (
                                      <option value={arrayElement.id} key={index + 1}>
                                        {arrayElement.name}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Select>
                            </div>
                          </div>}></SkeletonCom>
                      <SkeletonCom loader={loderinapi}
                        element={
                          <div
                            className="conteanaha_data_ketete"
                            style={{ width: '14%' }}
                          >
                            <div className=" balance_cat">
                              <Form.Select
                                name="supplier_id"
                                onChange={(e: any) => {
                                  setSelect_data(e.target.value);
                                  var serdata: any = {
                                    search: search,
                                    startdate: startDate,
                                    enddate: endDate,
                                    payee: e.target.value,
                                    type: data_type,
                                    pagedata: pageneshn,
                                    category: categ,
                                    select_amount: select_amount,
                                  };
                                  getBalanceList(serdata);
                                }}
                                value={select_data}
                              >
                                <option selected value="">
                                  Select payee
                                </option>
                                {counterpartsList_two?.map(
                                  (arrayElement: any, index: number) => {
                                    return (
                                      <option
                                        value={arrayElement.monite_counterpart_id}
                                        key={index + 1}
                                      >
                                        {arrayElement.counterpart_type == 'individual'
                                          ? arrayElement.contact_first_name +
                                          ' ' +
                                          arrayElement.contact_last_name
                                          : arrayElement.name}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Select>
                            </div>
                          </div>}></SkeletonCom>
                      <SkeletonCom loader={loderinapi}
                        element={
                          <div
                            className="conteanaha_data_ketete"
                            style={{ width: '20%' }}
                          >
                            <DatePickerll
                              placeholderText="Select dates"
                              dateFormat="dd MMMM yyyy"
                              className="dashboard-container__datepicker"
                              selectsRange
                              selected={startDate}
                              startDate={startDate}
                              endDate={endDate}
                              maxDate={today}
                              onChange={(dates: any) => {
                                const [start, end] = dates;
                                setStartDate(start);
                                setEndDate(end);
                                console.log(dates, 'clicked range');
                              }}
                            />

                          </div>}></SkeletonCom>
                      <SkeletonCom loader={loderinapi}
                        element={
                          <div
                            className="conteanaha_data_ketete"
                            style={{ width: '16%' }}
                          >
                            <div className=" balance_cat">
                              {!!datatabal && datatabal.length > 0 && (
                                <>
                                  <Button
                                    onClick={() => {
                                      setDounload_popup_date(true);
                                      //setDounload_popup(true);
                                    }}
                                    className="Download_Button"
                                    style={{ textAlign: 'left' }}
                                  >
                                    <MobileIcon />
                                    <p> Download</p>
                                  </Button>

                                </>
                              )}
                            </div>

                          </div>}></SkeletonCom>

                    </div>
                  </div>

                  <div className="balance__table">
                    <>
                      <SkeletonCom loader={loderinapi}
                        element={
                          <BalanceTable
                            loderdata={loderinapi}
                            data={datatabal}
                            onSelect={(el: any) => {
                              console.log('ele', el);

                              setshowTransMoodal(true);
                              settransData(el);
                            }}
                          />}></SkeletonCom>
                      <SkeletonCom loader={loderinapi}
                        element={
                          <div className="addnewbtnclass">
                            <div className="inpagenum">
                              <p>
                                {remove - 9}-{remove}
                              </p>
                            </div>
                            <div className="btnmaindivin">
                              {pageneshn == 1 ? (
                                <button disabled className="nextntmistish">
                                  <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                </button>
                              ) : (
                                <button
                                  className="nextntmistish"
                                  onClick={() => {
                                    setPagelist(pageneshn - 1);
                                    setRemovedata(remove - 10);
                                  }}
                                >
                                  <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                </button>
                              )}
                              {remove > totalCount ? (
                                <button disabled className="nextntmistish">
                                  <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                </button>
                              ) : (
                                <button
                                  className="nextntmistish"
                                  onClick={() => {
                                    setPagelist(pageneshn + 1);
                                    setRemovedata(remove + 10);
                                  }}
                                >
                                  <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                </button>
                              )}
                            </div>
                          </div>}></SkeletonCom>
                    </>
                  </div>
                </>
              )}
            </div>
          </>

          // :}
          // </>
        )}
        {Dounload_popup_date && (
          <DatePopup
            // data={csvUrl}
            show={Dounload_popup_date}
            onClose={() => {
              setDounload_popup_date(false);
            }}
            OnSave={(data: any) => {
              //setSelect_popup(data);
              setStartDate(data[0]);
              setEndDate(data[1]);
              setDounload_popup(true);
              setDounload_popup_date(false);
            }}
          />
        )}
        {Dounload_popup && (
          <SelectPopup
            data={csvUrl}
            show={Dounload_popup}
            onClose={() => {
              setDounload_popup(false);
            }}
            OnSave={(data: any) => {
              setSelect_popup(data);
              setDounload_popup(false);
              if (data == 'Export as CSV') {
                setDounload_popup_pdf(true);
              } else if (data == 'Export as PDF') {
                if (!csvUrl?.pdf) {
                  setShowModal(true);
                } else {
                  window.open(csvUrl?.pdf, '_blank');
                }
              }
            }}
          />
        )}
        {Dounload_popup_pdf && (
          <Popup
            data={csvUrl}
            show={Dounload_popup_pdf}
            onClose={() => {
              setDounload_popup_pdf(false);
            }}
            search={search}
            startdate={startDate}
            enddate={endDate}
            OnSave={(data: any) => {
              setDounload_popup_pdf(false);
            }}
          />
        )}
        {topup_balk_data && (
          <Topup_balk_data
            show={topup_balk_data}
            onClose={() => {
              setShowBalanceGrid(true);
              setTopup_balk_data(false);
            }}
          />
        )}
        {currencySelect &&
          <Select_source_account onSave={(el: any) => {
            // if (el == "KES") {
            setShowTopup_two(true);
            setCurrencySelect(false)
            setSelectCurrency(el)
            // } else {
            //   setSelectMethod(true)
            //   setCurrencySelect(false)
            //   setSelectCurrency("USD")
            // }

          }}
            currencyAndMethodData={currencyAndMethodData}
            pageName="Balance"
            onClose={() => {
              setCurrencySelect(false)
            }}
            show={currencySelect}

          />

        }
        {/* {selectMethod &&

          <SelectMethod
            onSave={(el: any) => {
              setSelectMethod(false)
              if (el == "Express Payment USD") {
                navigate(`/payment-journey?type=${el}`);
              }
            }}
            onSaveCurrancy={(el: any) => {
              if (el == "KES") {
                setShowTopup_two(true);
                setCurrencySelect(false)
                setSelectMethod(false)
                setSelectCurrency("KES")
              } else {
                setSelectMethod(true)
                setCurrencySelect(false)
                setSelectMethod(false)
                setSelectCurrency("USD")
              }

              setSelectCurrency("KES")
            } else {
                navigate(`/payment-journey?type=${el}`)
              
              // setSelectMethod(true)
              // setCurrencySelect(false)
              // setSelectCurrency("USD")
            }

            }}
            onClose={() => {
              setSelectMethod(false)
            }}
            values={SelectCurrency}
            show={selectMethod}
          />

        } */}
        {showTopup_two && (
          <TopupModal_two
            show={showTopup_two}
            onSaveCurrancy={(el: any) => {
              // if (el == "KES") {
              // setShowTopup_two(true);
              if (el == "KES") {
                setCurrencySelect(false)
                setShowTopup_two(false);
                setSelectCurrency(el)
              } else {

              }
            }}
            countryList={countryList}
            values={SelectCurrency}
            onClose={() => {
              setShowTopup_two(false);
            }}
            onSave={(el: boolean) => {
              setShowTopup_two(false);
              setTopupMethod(el);
              setTopup(true);
              setShowBalanceGrid(false);
            }}
            onBalkpaymeant={(el: boolean) => {
              setShowTopup_two(false);
              setTopupMethod(el);
              setTopup_balk_data(true);
              setShowBalanceGrid(false);
            }}
            onconfiam={(el: boolean) => {
              setPaymentGrid(true);
              setShowBalanceGrid(false);
              setTopup(false);
            }}
          />
        )}
        {showTopup && (
          <TopupModal
            currencyAndMethodData={currencyAndMethodData}
            show={showTopup}
            onClose={() => {
              const event = 'Top up canceled from Balance';
              const eventProperties = {
                userId:
                  !!properties && !!properties.user_id
                    ? properties.user_id
                    : null,
                userName:
                  !!properties && !!properties.name ? properties.name : null,
                email:
                  !!properties && !!properties.email ? properties.email : null,
                pageUrl: window.location.href,
              };
              amplitude.getInstance().logEvent(event, eventProperties);
              posthog.capture(event, eventProperties);
              setShowTopup(false);
              setShowBalanceGrid(true);
            }}
            onSave={(el: boolean) => {
              setShowTopup(false);
              setTopupMethod(el);
              setTopup(true);
              setShowBalanceGrid(false);
            }}
            select_Currency={select_amount}
            pageName="Balance"
          />
        )}

        {showTransMoodal && (
          <TransactionDetails
            show={showTransMoodal}
            onClose={() => {
              setshowTransMoodal(false);
              settransData('');
            }}
            data={transData}
          />
        )}
        {verefire_data && (
          <Virefily_account
            show={verefire_data}
            onClose={() => {
              setVirefay_data(false);
            }}
          />
        )}
        {topUp && (
          <TopupDetail
            topupMethod={topUpMethod}
            onReturn={() => {
              setTopup(false);
              setShowBalanceGrid(true);
            }}
          />
        )}
        {paymentGrid && (
          <PayableGrid
            onCancel={() => {
              setShowTopup_two(false);
              setPaymentGrid(false);
              setShowBalanceGrid(true);
            }}
          />
        )}
        <></>
      </ThemeProvider>
    </>
  );
};
const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 11 11"
    fill="none"
  >
    <path d="M5.3333 7.50039V4.90039" stroke="#025041" stroke-linecap="round" />
    <path
      d="M5.33333 3.16654C5.57265 3.16654 5.76666 3.36055 5.76666 3.59987C5.76666 3.83919 5.57265 4.0332 5.33333 4.0332C5.094 4.0332 4.89999 3.83919 4.89999 3.59987C4.89999 3.36055 5.094 3.16654 5.33333 3.16654Z"
      fill="#025041"
    />
    <path
      d="M3.16666 1.5798C3.82512 1.19886 4.57261 0.998833 5.33333 1.00001C7.72662 1.00001 9.66665 2.94004 9.66665 5.33333C9.66665 7.72663 7.72662 9.66666 5.33333 9.66666C2.94003 9.66666 1 7.72663 1 5.33333C1 4.54423 1.21103 3.80367 1.5798 3.16667"
      stroke="#025041"
      stroke-linecap="round"
    />
  </svg>
);
const MobileIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 11.9412V13.4706C3 13.8762 3.15804 14.2652 3.43934 14.552C3.72064 14.8389 4.10218 15 4.5 15H13.5C13.8978 15 14.2794 14.8389 14.5607 14.552C14.842 14.2652 15 13.8762 15 13.4706V11.9412M5.25 7.35294L9 11.1765M9 11.1765L12.75 7.35294M9 11.1765V2"
      stroke="#025041"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
